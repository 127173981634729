.titleLink {
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  &:hover {
    color: #fff;
  }
}
/* Separator between breadcrumb items */
.breadcrumb:not(:last-child)::after {
  content: ">";
  display: inline-block; /* Make it an inline block to apply width and height */
  text-align: center;
  vertical-align: middle;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 4px;
  margin-left: 8px;
  font-size: 20px;
  height: 16px;
  width: 16px;
  color: #fff; /* You can use your desired separator color */
  line-height: 18.52px;
  // font-weight: bold; /* Apply font-weight to the separator */
}
