.autocompleteDropdown::after {
  display: block;
  text-align: right;
  padding: 5px 15px 0px 0px;
  content: url("../../../../assets/images/powered-by-google.png");
}
.autoCompleteInputFieldConatiner {
  //   display: flex;
  //width: 100%;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: flex-start;
  //   gap: 8px;
  position: relative;
}
.autoCompleteInputFieldName {
  color: var(--T---Black, #000);
  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.autoCompleteInput {
  display: flex;
  padding: 8px 0px 8px 16px;
  //width: 258px;
  width: 100%;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-05, #d2d7de);
  //background: var(--Gray-08, #f4f5f8);
  //backdrop-filter: blur(5px);
  color: var(--Primary-Gray, #393a3d);
  /* Regular Text/16pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.autocompleteDropdown {
  background-color: #fff;
  border: 1px solid var(--Gray-05, #d2d7de);
  border-radius: 8px;
  padding: 14px 0;
  position: absolute;
  margin-top: 5px;
  z-index: 1;
  width: 100%;
  cursor: pointer;
}
.autocompleteSuggestions {
  padding: 5px 16px 5px 16px;
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  /* Regular Text/16pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.autocompleteSuggestions:hover {
  background-color: #f4f5f8;
}
