.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 14px 0 0 0;
  width: 100%;
  align-self: stretch;
}
.page-item {
  margin: 0 5px;
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;

  display: flex;
  align-items: center;
  gap: 6px;
}
.rowPerPage {
  color: #393a3d;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-left: 16px;
}
.arrowContainerPagination {
  display: flex;
  height: 36px;
  //padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  // border-radius: 8px;
  // border: 1px solid var(--Gray-04, #b8bec7);
  // border: 1px solid var(--Gray-04, color(display-p3 0.7294 0.7451 0.7725));
  background: var(--colors-White, #fff);
  background: var(--colors-White, color(display-p3 1 1 1));
}
.page-link {
  color: #393a3d;
  cursor: pointer;
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
}

.page-link.active {
  font-weight: bold;
}
.page-link:hover {
  //color: #393a3d;
}
.page-link-input {
  color: #393a3d;
  cursor: pointer;
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;

  display: flex;
  width: 51px;
  height: 32px;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--Gray-04, #b8bec7);
  background: var(--Gray-08, #f4f5f8);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08) inset;
}
.page-link-input.active {
  font-weight: 500;
  font-size: 16px;
}
.form-select {
  // margin-top: 10px;
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../../../../assets/images/poc/dropdownPaginationArrow.svg")
    no-repeat right 0.4rem center !important;
  background-size: auto 100%;
  display: flex;
  width: 60px;
  height: 32px;
  padding: 4px 12px;
  //justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--Gray-04, #b8bec7);
  background: var(--colors-White, #fff);
}
.custom-select {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../../../assets/images/poc/dropdownPaginationArrow.svg")
    no-repeat right center !important;
  background-size: auto 100%;
  padding-left: 40px; /* Adjust this value according to the width of your arrow icon */
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select img {
  margin-right: 10px; /* Adjust the margin as needed */
}
/* Style for the select dropdown */
.select-dropdown::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30px; /* Adjust width as needed */
  background: #ffffff; /* Set desired background color */
  pointer-events: none; /* Ensure the background doesn't interfere with clicks */
  z-index: 1; /* Ensure the background is behind the dropdown items */
}
.myMenuClassName {
  z-index: 1;
}
