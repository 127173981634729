.greeting {
  display: flex;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.vehiclePOC {
  background-color: #f9fafb;
  position: relative;
  padding: 0 48px 24px;
  //overflow: hidden;
}
.stickyRowVehicle {
  position: sticky;
  top: 0;
  right: 0;
  //background-color: #f9fafb;
  z-index: 2;
  padding-top: 24px;
  //margin-bottom: 16px;
}
.headerVehicle {
  display: flex;
  //align-items: center;
  gap: 10px;
  align-self: stretch;

  //padding-bottom: 25px;
  //padding-left: 48px;
}
.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}
.actionsConatiner {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.stickyDetailsHeader {
  position: sticky;
  top: 120px;
  right: 0;
  z-index: 1;
  //margin-bottom: 1px;
}
.stickyTabButtonsVehicles {
  position: sticky;
  top: 100px;
  right: 0;
  z-index: 2;
  background-color: #f9fafb;
  padding-top: 32px;
  //padding-bottom: 16px;
  margin-bottom: 16px;
}
.tableContainerVehicle {
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  border-top: 0;
  background: #fff;
  //position: sticky;
  // top: 20px;
  // right: 0;
  // z-index: 1;
}
.tableTitleContainerVehicle {
  position: sticky;
  //top: 175px;
  //right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  border-bottom: 0;
  margin-top: 16px;
}
.searchBox {
  display: flex;
  width: 214px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-05, #d2d7de);
  background: var(--Gray-09, #fcfcfc);
}
.searchTextStyle {
  color: var(--Gray-02, #6b6c73);
  //text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
}
.driversTableContainerVehicle {
  //position: relative;
  max-height: 550px;
  overflow-x: auto;
  position: sticky;
  //top: 200px;
  right: 0;
  z-index: 1;
  padding: 0px 16px 16px 16px;
}
.driversTableContainerVehicle::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
.driversTableContainerVehicle::-webkit-scrollbar-thumb {
  //background-color: rgba(0, 0, 0, 0);
}
.driversTableContainerVehicle::-webkit-scrollbar-track {
  // background-color: rgba(0, 0, 0, 0);
}
