$primary-color: #000000;
$secondary-color: #000;
$white-color: #fff;
$ant-menu-item-selected: #ee4c3312;
$ant-btn-ghost-border-color: #fff;
$light-primary-color: #ef7461;
$input-text-color: #090e0f;
$input-border-color: #ebebeb;
$text-default: #767676;
$text-danger: #f40e0e;
$text-warning: #ffbf00;
