.pickupLocationConatiner {
  display: flex;
  padding: 0px 24px 0px 8px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.hoverable:hover {
  background-color: rgba(195, 206, 222, 0.3);
  padding: 0px 24px 0px 8px;
}
.pickupPointContainer2 {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  //border: 1px solid var(--Gray-05, #d4d7dc);
  background: var(--white-100, #fff);
}
.dotsSixVerticalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pickupPointContainer {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid var(--Gray-05, #d4d7dc);
  background: var(--white-100, #fff);
}

.locationSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //gap: 24px;
  flex: 1 0 0;
}
.locationSectionHover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  flex: 1 0 0;
}
.locationTitleContainer {
  display: flex;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.locationTitle {
  display: flex;
  height: 14px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: var(--black-80, rgba(0, 0, 0, 0.8));
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.locationDateTime {
  display: flex;
  align-items: center;
  gap: 20px;
}
.dateTimeContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}
.dotContainer {
  overflow: hidden;
  color: var(--T---Black, #000);
  text-overflow: ellipsis;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
}
.dateTime {
  overflow: hidden;
  color: var(--T---Black, #000);
  text-overflow: ellipsis;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.locationDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-bottom: 32px;
}
.rowContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.addInstructionButton {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}

.addInstructionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;
}

.addInstructionLabel {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.addInstructionInputContainer {
  display: flex;
  height: 80px;
  padding: 8px 8px 8px 16px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  //backdrop-filter: blur(5px);
  min-width: 100%;
  width: 100%;
  resize: none;
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.addInstructionInput {
  width: 100%;
  height: 100%;
  border: none;
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.hoveringOptions {
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.showMoreOptionsContainer {
  display: flex;
  height: 40px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  border: 1px solid var(--Gray-05, #d4d7dc);
  background: #fff;
}
.vertical-line-visible {
  border-right: 1px solid #d4d7dc; /* Adjust thickness and color as needed */
  height: 100%;
  margin: 5px 30px 5px 62px;
}
.vertical-line {
  border-right: 1px solid #d4d7dc; /* Adjust thickness and color as needed */
  height: 100%;
  margin: 5px 30px 5px 70px;
}
.spaceDiv {
  display: flex;
  padding: 32px 0px 32px 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  flex: 1;
}
.minMilesBetween {
  display: flex;
  flex: 1;
  padding: 6px 4px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--T---LT-Gray, #eceef1);
  //width: auto;
  white-space: nowrap;
}
.minBetween {
  display: flex;
  align-items: center;
  color: var(--Primary-Gray, #393a3d);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dotBetween {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Gray-03, #8d9096);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.milesBetween {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--Primary-Gray, #393a3d);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.horizontalDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e3e5e8;
  margin: 0;
  width: auto;
  flex: 3;
  white-space: nowrap;
}
.deleteStopPopup {
  position: relative;
}
