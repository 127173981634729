.inputSectionAddDriverContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  padding: 24px;
}
.inputSectionAddDriver {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.inputSectionTitleDriver {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.inputRowDriver {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  align-self: stretch;
}
.loginCTABox {
  display: flex;
  min-width: 400px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.payPlan {
  display: flex;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
}
.toast-message {
  //display: flex;
  //padding: 8px 12px 8px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  // background: rgba(0, 188, 249, 0.1);
  // background: color(display-p3 0 0.724 0.9526 / 0.1);
  color: var(--Blue, var(--Blue, #00bfff));
  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Toastify__toast {
  height: auto !important;
  min-height: auto !important;
}
