.driver-table:hover {
  background-color: #fafafa; /* Change the background color on hover */
  cursor: pointer;
}
.tableStickyHeader {
  position: sticky;
  top: 0px;
  right: 0;
  z-index: 1;
}
