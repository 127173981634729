.driver-header {
  display: flex;
  padding: 8px 0px;
  //justify-content: center;
  align-items: center;
  align-content: center;
  gap: 32px 0px;
  align-self: stretch;
  flex-wrap: wrap;
  //height: 72px;
  border-radius: 12px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  background: var(--White, #fff);
  //margin-top: 24px;
  width: 100%;
}
.details-box {
  display: flex;
  //justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  // min-width: 180px;
  // max-width: 222px;
  padding: 8px 24px;
  gap: 12px;
  white-space: nowrap;
}
.details-box input::placeholder {
  color: #babec5; /* Change placeholder color to gray */
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  background: var(--Gray-09, #fcfcfc);
}
.valueHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.valueHeader {
  color: var(--Primary-Gray, #393a3d);
  //font-family: "Red Hat Display", sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  border: none;
}
//For Chrome and Safari
.valueHeader[type="number"]::-webkit-inner-spin-button,
.valueHeader[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Remove default arrow buttons */
  margin: 0; /* Optional: adjust positioning if needed */
}
//For Firefox
.valueHeader[type="number"] {
  -moz-appearance: textfield; /* Remove default arrow buttons */
}
.titleHeader {
  color: var(--Gray-02, #6b6c73);
  //font-family: "Red Hat Display", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
