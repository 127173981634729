@import "./../../../../assets/styles/mixin";
@import "./../../../../assets/styles/colors";
#transport_admin_header {
  padding: 0.8355rem 4.7rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 4px 0px, rgba(0, 0, 0, 0.05) 0px 2px 8px 0px;
  // height: 4.25rem;
  position: relative;
  z-index: 999;
  height: auto;
  border-bottom: 1px solid #e4e4e4;
  line-height: initial;
  user-select: none;
  @include screen("desktop") {
    padding: 0.5rem 4.7rem;
  }
  @include screen("tablet") {
    height: auto;
    padding: 9px 49px;
  }
  @include screen("landscape") {
    padding: 10px 14px;
    line-height: initial;
  }
  .logo-wrapper {
    width: 10rem;
    display: flex;
    align-items: center;
    @include screen("desktop") {
      width: 150px;
    }
    @include screen("tablet") {
      width: 178px;
    }
    @include screen("landscape") {
      width: 112px;
    }
    img {
      width: 100%;
    }
  }
  .profile-wrapper {
    width: 600px;
    text-align: right;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-end;
    @include screen("tablet") {
      width: auto;
      flex-direction: row-reverse;
    }
    .profile-name {
      text-transform: capitalize;
      width: calc(100% - 2.5rem);
      color: $white-color;
      padding-right: 1rem;
      @include screen("tablet") {
        display: none;
      }
    }
    .profile-img {
      margin-right: 60px;
      @include screen("desktop") {
        margin-right: 45px;
      }
      @include screen("tablet") {
        margin-right: 0;
        margin-left: 60px;
      }
      @include screen("landscape") {
        margin-left: 30px;
      }
      > span {
        color: $input-text-color;
        background-color: $input-border-color;
        line-height: 50px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
        @include screen("tablet") {
          line-height: 50px;
        }
        @include screen("landscape") {
          width: 26px;
          height: 26px;
          line-height: 21px;
        }
        span {
          @include font-size(20);
          font-weight: 500;
          letter-spacing: 2px;
          // margin-left: 1px;
          @include screen("tablet") {
            @include font-size($size: 21, $base: 16);
          }
          @include screen("landscape") {
            @include font-size($size: 12, $base: 16);
          }
          svg {
            path {
              fill: $input-text-color;
            }
          }
        }
      }
      &:focus {
        outline: none;
      }
    }
    .unregistered_user {
      cursor: pointer;
      .profile-avatar {
        &:focus {
          outline: none;
        }
      }
    }
    #transport_language_dropdown {
      > svg {
        stroke: $white-color;
        .a,
        .b {
          stroke: $white-color;
        }
      }
      .ant-select-selector {
        background: transparent;
        border: none;
        color: $white-color;
      }
      .ant-select-arrow {
        color: $white-color;
      }
    }
  }
  .action-icons-wrapper {
    width: 8rem;
    text-align: right;
  }
}
#logout_confirmation {
  padding: 3.25rem 1.65rem;
  button {
    min-width: 170px;
  }
  .message {
    @include font-size(22);
    font-weight: 500;
    margin-bottom: 1.65rem;
    color: #000;
  }
  .yes {
    margin-right: 0.5rem;
  }
  .cancel {
    margin-left: 0.5rem;
  }
}
