.updateVehicleCard {
  background-color: #fff;
  position: relative;
  top: 20%;
  left: 45%;
  transform: translate(-42%, -18%);
  border-radius: 24px;
  //width: 1180px;
  width: 65%;
}
.updateVehicleContainer {
  display: flex;
  padding: 32px;
  //width: 400px;
  //height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  border-radius: 24px;
  border: 1px solid var(--Gray-05, #d2d7de);
  border: 1px solid var(--Gray-05, color(display-p3 0.8314 0.8431 0.8627));
  background: #fff;
  box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 24px 48px 0px color(display-p3 0 0 0 / 0.08);
  backdrop-filter: blur(4px);
}
.updateVehicleTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  white-space: nowrap;
}
.updateVehicleStyle {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.closeAddVehicle {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 80px;
  border: 1px solid var(--Gray-05, #d2d7de);
  border: 1px solid var(--Gray-05, color(display-p3 0.8314 0.8431 0.8627));
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
}
.inputSectionVehicle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.inputRowVehicle {
  display: flex;
  align-items: center;
  gap: 24px;
}
.submitButtonVehicle {
  display: flex;
  min-width: 400px;
  justify-content: right;
  align-items: center;
  align-self: stretch;
}
@media screen and (min-width: 320px) and (max-width: 1099px) and (min-height: 700px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (min-width: 1100px) and (max-width: 1299px) and (min-height: 700px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (width: 1194px) and (height: 706px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (min-width: 1300px) and (max-width: 1379px) and (min-height: 800px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (width: 1366px) and (height: 896px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (min-width: 1380px) and (max-width: 1599px) and (min-height: 850px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -18%);
  }
}
@media screen and (width: 1440px) and (height: 900px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -18%);
  }
}
@media screen and (min-width: 1600px) and (max-width: 1700px) and (min-height: 950px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
@media screen and (width: 1680px) and (height: 1050px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
@media screen and (min-width: 1701px) and (min-height: 950px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
@media screen and (width: 1728px) and (height: 989px) {
  .updateVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
