// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

.headerCell {
  display: flex;
  min-height: 40px;
  padding: 12px 16px !important;
  //justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--Gray-08, #f9fafb);
  border: 0px solid var(--Gray-05, #d2d7de) !important;
  //overflow: hidden;
  color: var(--Primary-Gray, #393a3d) !important;
  text-overflow: ellipsis;
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  white-space: nowrap;
}
.smallBodyCell {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 10px 16px !important;
  gap: 8px;
  border: 0px solid var(--Gray-05, #d2d7de) !important;
  //overflow: hidden;
  color: var(--Primary-Gray, #393a3d) !important;
  text-overflow: ellipsis;
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  white-space: nowrap;
}
.mediumBodyCell {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 14px 16px !important;
  gap: 8px;
  border: 0px solid var(--Gray-05, #d2d7de) !important;
  //overflow: hidden;
  color: var(--Primary-Gray, #393a3d) !important;
  text-overflow: ellipsis;
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  white-space: nowrap;
}
.largeBodyCell {
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 18px 16px !important;
  gap: 8px;
  border: 0px solid var(--Gray-05, #d2d7de) !important;
  overflow: hidden;
  color: var(--Primary-Gray, #393a3d);
  text-overflow: ellipsis;
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400;
  line-height: normal !important;
  white-space: nowrap;
}
.specialCase {
  display: flex !important;
  padding: 6px 8px !important;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  overflow: hidden;
  color: var(--Primary-Gray, #393a3d) !important;
  text-overflow: ellipsis;
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  white-space: nowrap;
}
