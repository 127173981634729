.recommendedVehicleSection {
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  //border-bottom: 1px solid #e3e5e8;
}
.detailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}
.driverDetails {
  color: var(--black-80, rgba(0, 0, 0, 0.8));
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.vehicleDetails {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.routeDetails {
  display: flex;
  align-items: center;
  gap: 4px;
}
.routeMiles {
  overflow: hidden;
  color: var(--T---Black, #000);
  text-overflow: ellipsis;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dotContainer {
  overflow: hidden;
  color: var(--Gray-02, #6b6c72);
  text-overflow: ellipsis;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
}
.checkBoxContainer {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 76.8px;
  border: 1px solid var(--Gray-05, #d4d7dc);
}
.imageCheckBoxContainer {
  // width: 40px;
  // height: 40px;
}
