.recommendVehicleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  background: #fff;
}
.imageVehicleSection {
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  //border-bottom: 1px solid var(--Gray-06, #e3e5e8);
}
.titleSearchContainer {
  display: flex;
  padding: 17px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--border-60, rgba(195, 206, 222, 0.6));
}
.titleContainer {
  color: var(--T---Black, #000);
  /* Regular Text/18pt/Semibold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.searchContainer {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  //backdrop-filter: blur(5px);
}
.imageMessageSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 100%;
  width: 100%;
}
.messageContainer {
  display: flex;
  height: 12px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: var(--Gray-03, #8d9096);
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}
