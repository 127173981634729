.advancedDetailsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  background: #fff;
}
.advancedDetailsTitleContainer {
  display: flex;
  padding: 17px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  //border-bottom: 1px solid var(--border-60, rgba(195, 206, 222, 0.6));
}
.advancedDetailsTitle {
  color: var(--T---Black, #000);
  /* Regular Text/18pt/Semibold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.advancedDetailsDownArrow {
  display: flex;
  height: 40px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 56px;
  border: 0px solid var(--Gray-05, #d4d7dc);
  background: #fff;
  cursor: pointer;
}
.advancedDetailsContainer {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.rowContent {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
