// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

.row-selection {
  display: flex;
  //width: 1314px;
  padding: 12px 12px 12px 16px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--Gray-06, #e3e5e8);
}
.row-title {
  overflow: hidden;
  color: var(--T---Black, #000);
  text-overflow: ellipsis;
  /* Old/Body 02 - Reg */
  font-family: "Noto Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
}
.row-subtitle {
  overflow: hidden;
  color: var(--Gray-02, #6b6c73);
  text-overflow: ellipsis;
  /* Old/Body 03 - Reg */
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.row-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}
.action {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-05, #d2d7de);
  color: var(--T---Black, #000);
  text-align: center;
  /* Special Use/CTA-Small */
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.options {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-05, #d2d7de);
}
.rows-selected {
  overflow: hidden;
  color: var(--Info, #009aed);
  text-overflow: ellipsis;
  /* Body 03 - Reg */
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
