.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}
.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  //margin-top: 24px;
}
.searchNotificationBellContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
.searchBoxContainer {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 36px;
  background: rgba(255, 255, 255, 0.1);
}
.searchInputStyle {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  background: transparent;
  color: var(--White, #fff);
  //text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  opacity: 0.6;
  padding: 0;
  border: none;
}
.notificationBellContainer {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 36px;
}
