@import "./colors";
@import "./mixin";

// To adjust position of icons in buttons-icons
.ant-btn > .anticon {
  line-height: 2 !important;
}

.ant-menu-submenu-selected {
  color: $primary-color !important;
}

.ant-menu-submenu:hover {
  color: $primary-color !important;
}

.ant-menu-submenu-active {
  color: $primary-color !important;
}
.ant-btn > .anticon {
  line-height: 1rem !important;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $primary-color !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid $primary-color !important;
}

.ant-menu-item-selected {
  color: $primary-color !important;
}

.ant-menu-item:hover {
  color: $primary-color !important;
}

.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, $primary-color, $primary-color);
}

.ant-switch-checked {
  background-color: $primary-color;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $ant-menu-item-selected;
}

.ant-btn-ghost {
  color: $white-color;
}

.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  border-color: $ant-btn-ghost-border-color;
  color: $primary-color;
  background-color: $white-color;
}

.ant-input:focus,
.ant-input:hover,
.ant-input-focused {
  border-color: $light-primary-color;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  color: $white-color;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border: 0;
  background: rgba(255, 255, 255, 0.15);
  color: $white-color !important;
}
.ant-menu-horizontal > .ant-menu-item-selected {
  background: transparent;
  color: $white-color !important;
}
.app-page-container {
  .ant-layout-header {
    background-color: transparent;
  }
}
.ant-upload-picture-card-wrapper {
  display: block;
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
    margin-right: 0;
    border: 2px dashed $white-color;
    background-color: transparent;
    margin-bottom: 0px;
    border-radius: 0.7rem;
    overflow: hidden;
    &:hover {
      border-color: $white-color;
    }
    .ant-upload {
      padding: 0;
      img {
        display: block;
        max-width: 100%;
        height: auto;
        // width: 100%;
        // object-fit: contain;
      }
    }
  }
}
.theme-default {
  .ant-layout {
    background-color: #f9fafb;
  }
}
