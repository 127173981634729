@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
.tableHeader {
  display: table-cell;
  //min-height: 48px;
  width: 100% !important;
  //padding: 12px 16px !important;
  //justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--Gray-08, #ffffff);
  border-top: 1px solid var(--Gray-05, #d2d7de) !important;
  border-bottom: 1px solid var(--Gray-05, #d2d7de) !important;
  overflow: hidden;
  color: var(--Primary-Gray, #393a3d) !important;
  text-overflow: ellipsis;
  /* Regular Text/12pt/Medium */
  // font-family: Red Hat Display !important;
  // font-size: 12px !important;
  // font-style: normal !important;
  // font-weight: 600 !important;
  // line-height: normal !important;
}
.tableHeader:hover {
  background-color: #fafafa; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor on hover, indicating interactivity */
}
.tableHeaderCell {
  display: table-cell;
  min-height: 48px;
  //width: auto;
  padding: 14px 16px !important;
  //margin-left: 20px;
  //justify-content: center;
  align-items: center;
  gap: 8px;
  //background: var(--Gray-08, #f4f5f8);
  border: 0px solid var(--Gray-05, #d2d7de) !important;
  overflow: hidden;
  color: var(--Primary-Gray, #393a3d) !important;
  text-overflow: ellipsis;
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.tableHeaderCellImage {
  display: table-cell;
  min-height: 40px;
  //width: fit-content !important;
  //margin-left: auto !important;
  padding: 14px 16px !important;
  //justify-content: center;
  align-items: flex-end;
  gap: auto;
  //background: var(--Gray-08, #f4f5f8);
  border: 0px solid var(--Gray-05, #d2d7de) !important;
  overflow: hidden;
  color: var(--Primary-Gray, #393a3d) !important;
  text-overflow: ellipsis;
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.lastCell {
  margin-left: auto !important;
}
