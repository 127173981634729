.calenderChangeButton {
  display: flex;
  //align-items: flex-start;
  border-radius: 52px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  height: 32px;
  position: relative;
}
.arrowLeftContainer {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  //border: 0px solid var(--Gray-05, #d2d7de);
  background: var(--Gray-08, #f4f5f8);
  border-top-left-radius: 52px;
  border-bottom-left-radius: 52px;
  cursor: pointer;
}
.arrowRightContainer {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  //border: 0px solid var(--Gray-05, #d2d7de);
  background: var(--Gray-08, #f4f5f8);
  border-top-right-radius: 52px;
  border-bottom-right-radius: 52px;
  cursor: pointer;
}
.calendarValueContainer {
  position: relative;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-left: 1px solid var(--Gray-05, #d2d7de);
  border-right: 1px solid var(--Gray-05, #d2d7de);
  background-color: #ffffff;
  color: var(--Primary-Gray, #393a3d);
  text-align: center;
  font-family: "Red Hat Display", sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */

  img {
    cursor: pointer;
  }
}
.calendarDropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  //align-items: center;
  gap: 12px;
  align-self: stretch;
  top: 110%;
  left: 0;
  border: 1px solid var(--Gray-04, #b8bec7);
  background: var(--White, #fff);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other content */
  width: 100%;
  padding: 16px;
  min-height: fit-content;
  max-height: 16rem;
  overflow-y: auto;
  height: auto;
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  /* Regular Text/16pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  div {
    //padding: 10px;
    cursor: pointer;
    &:hover {
      //background-color: #f0f0f0;
    }
  }
}
