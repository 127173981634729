@import "./reset";
@import "./mixin.scss";
@import "./colors";

:root {
  font-size: 20px;
  @include screen("desktop") {
    font-size: 18px;
  }
  @include screen("tablet") {
    font-size: 16px;
  }
}

body {
  font-size: 1rem;
}

.text-danger {
  color: $text-danger;
}
.text-warning {
  color: $text-warning;
}
.main-page-design {
  padding: 0 24px;
}
.details-page-design {
  padding: 0 24px;
  //background-color: #f9fafb;
}
