.roadMapContainer {
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  background: #fff;
}
.originDestinationContainer {
  //display: flex;
  padding: 20px 24px;
  //gap: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--border-60, rgba(195, 206, 222, 0.6));
  width: 100%;
  flex: 1 0;
}
.originConatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}
.originCity {
  color: var(--black-80, rgba(0, 0, 0, 0.8));
  text-align: right;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.orignDateTime {
  color: var(--Primary-Gray, #393a3d);
  text-align: right;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}
.destinationConatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}
.middleStops {
  display: flex;
  min-width: 24px;
  padding: 4px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 64px;
  background: var(--T---LT-Gray, #eceef1);
}
.totalStops {
  color: var(--Gray-02, #6b6c72);
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.mapContainer {
  width: 50%;
  //height: 100%;
}
