.page-title {
  color: var(--Primary-Gray, #393a3d);
  /* Regular Text/32pt/Bold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.table-title {
  color: var(--Gray-02, #6b6c73);
  color: var(--Gray-02, color(display-p3 0.4196 0.4235 0.4471));
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.card-title {
  color: var(--Gray-02, #6b6c73);
  color: var(--Gray-02, color(display-p3 0.4196 0.4235 0.4471));
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0;
}
