.stickyHeaderSectionLoads {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding-top: 24px;
  margin-bottom: 24px;
}
.kpiButtonTableSection {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 65px;
  right: 0;
  z-index: 2;
}
.tabButtonsCalendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  padding-top: 24px;
  padding-bottom: 24px;
}
.calenderChangeDownloadReportContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.stickyTabButtonsLoads {
  position: sticky;
  top: 100px;
  right: 0;
  z-index: 2;
  background-color: #f9fafb;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 52px !important;
}
.detailsHeaderContainer {
  //background-color: #f9fafb;
  border-radius: 12px !important;
}
.tableContainerLoads {
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  border-top: 0;
  background: #f9fafb;
}
.kpiTableTitleContainer {
  background-color: #f9fafb;
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tableTitleContainerLoads {
  position: sticky;
  //top: 175px;
  //right: 0;
  z-index: 2;
  display: flex;
  //justify-content: center;
  align-items: center;
  padding: 24px 16px;
  border-radius: 12px;
  background: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  border-bottom: 0;
  margin-top: 16px;
}
.searchBox {
  display: flex;
  width: 214px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-05, #d2d7de);
  background: var(--Gray-09, #fcfcfc);
}
.searchTextStyle {
  color: var(--Gray-02, #6b6c73);
  //text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
}
.driversTableContainerLoads {
  //position: relative;
  max-height: 550px;
  overflow-x: auto;
  position: sticky;
  //top: 200px;
  right: 0;
  z-index: 1;
  //padding: 0px 16px 16px 16px;
  background: #fff !important;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  //margin-right: 24px;
}
.driversTableContainerLoads::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
.driversTableContainerLoads::-webkit-scrollbar-thumb {
  //background-color: rgba(0, 0, 0, 0);
}
.driversTableContainerLoads::-webkit-scrollbar-track {
  // background-color: rgba(0, 0, 0, 0);
}
.calenderChangeButtonContainerLoads {
  display: flex;
  //flex-direction: column;
  justify-content: center;
  //align-items: flex-end;
  //padding-top: 32px;
  width: 100%;
}
#loads-chart {
  margin-top: 24px;
  height: 210px;
}
#loads-chart .apexcharts-legend-series {
  display: flex;
  align-items: center !important;
}
#loads-chart .apexcharts-legend-text {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-left: 0;
}
.slanted-lines {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  border: 1px solid white;
  overflow: hidden; /* To ensure pseudo-element stays within the div */
  margin-right: 3px;
}
.slanted-lines::before {
  content: "";
  position: absolute;
  top: -50%; /* Position it to cover the entire div */
  left: -50%; /* Position it to cover the entire div */
  width: 200%; /* Make it large enough to cover the div when rotated */
  height: 200%;
  background: repeating-linear-gradient(
    59deg,
    /* Angle of the lines */ white,
    /* Line color */ white 0px,
    /* Line thickness */ transparent 2px,
    /* Space after the line */ transparent 4px /* Space thickness */
  );
}
.triangle {
  width: 0;
  height: 0;
  border-top: 40px solid #007bff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  // border-bottom: 40px solid #007bff;
}
