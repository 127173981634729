.inputFieldConatiner {
  display: flex;
  //width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.inputFieldName {
  color: var(--T---Black, #000);
  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.inputFieldStyle {
  display: flex;
  padding: 8px 0px 8px 16px;
  //width: 258px;
  width: 100%;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-05, #d2d7de);
  border: 1px solid var(--Gray-05, color(display-p3 0.8314 0.8431 0.8627));
  background: var(--Gray-08, #f4f5f8);
  background: var(--Gray-08, color(display-p3 0.9569 0.9608 0.9725));
  //backdrop-filter: blur(5px);
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  /* Regular Text/16pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  //z-index: 0;
}
//For Chrome and Safari
.inputFieldStyle[type="number"]::-webkit-inner-spin-button,
.inputFieldStyle[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Remove default arrow buttons */
  margin: 0; /* Optional: adjust positioning if needed */
}
//For Firefox
.inputFieldStyle[type="number"] {
  -moz-appearance: textfield; /* Remove default arrow buttons */
}
