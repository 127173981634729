// .deleteStopCard {
//   background-color: #fff;
//   position: relative;
//   top: 20%;
//   left: 45%;
//   transform: translate(-42%, -18%);
//   border-radius: 24px;
//   //width: 1180px;
//   width: 40%;
// }
.deleteStopContainer {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--Gray-05, #d4d7dc);
  background: #fff;
  box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(4px);
  position: absolute;
  z-index: 1;
  top: -15px;
  left: 20px;
}
.deleteStopTitleMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.deleteStopTitle {
  display: flex;
  align-items: center;
  align-self: stretch;
  color: var(--Primary-Gray, #393a3d);
  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.deleteStopMessage {
  color: var(--Gray-02, #6b6c72);
  /* Regular Text/12pt/Regular */
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.deleteStopNumber {
  color: var(--T---Black, color(display-p3 0 0 0));

  /* Regular Text/18pt/Semibold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 4px 0 4px;
}
.removeSectionDeleteStop {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
// @media screen and (min-width: 320px) and (max-width: 1099px) and (min-height: 700px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-42%, -15%);
//   }
// }
// @media screen and (min-width: 1100px) and (max-width: 1299px) and (min-height: 700px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-42%, -15%);
//   }
// }
// @media screen and (width: 1194px) and (height: 706px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-42%, -15%);
//   }
// }
// @media screen and (min-width: 1300px) and (max-width: 1379px) and (min-height: 800px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-42%, -15%);
//   }
// }
// @media screen and (width: 1366px) and (height: 896px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-42%, -15%);
//   }
// }
// @media screen and (min-width: 1380px) and (max-width: 1599px) and (min-height: 850px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-42%, -18%);
//   }
// }
// @media screen and (width: 1440px) and (height: 900px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-42%, -18%);
//   }
// }
// @media screen and (min-width: 1600px) and (max-width: 1700px) and (min-height: 950px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-41%, -12%);
//   }
// }
// @media screen and (width: 1680px) and (height: 1050px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-41%, -12%);
//   }
// }
// @media screen and (min-width: 1701px) and (min-height: 950px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-41%, -12%);
//   }
// }
// @media screen and (width: 1728px) and (height: 989px) {
//   .assignVehicleCard {
//     top: 20%;
//     left: 45%;
//     transform: translate(-41%, -12%);
//   }
// }
