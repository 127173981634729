.settelmentsDriverPOC {
  background-color: #f9fafb;
  position: relative;
  padding: 0 48px 24px;
  //overflow: hidden;
}
.detailsStickyHeaderSettlementsDriverDetailsPOC {
  position: sticky;
  //top: 25px;
  top: 0;
  right: 0;
  //background-color: #fff;
  z-index: 2;
  padding-top: 24px;
  //padding-bottom: 16px;
  //padding-left: 48px;
}
.backToDrivers {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.backRightArrow {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
}
.backLink {
  display: flex;
  width: 35px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}
.backLink:hover {
  color: var(--Primary-Gray, #393a3d);
}
.detailsPage {
  background-color: #fff;
  position: relative;
  padding: 0 48px 24px;
  //overflow: hidden;
}
.serialNumbersSettlements {
  display: flex;
  align-items: center;
  gap: 2px;
}
.settlementIdSettlementsDriverDetailsPOC {
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.settlementValueSettlementsDriverDetailsPOC {
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.driverNo {
  color: #6b6c72;
  /* Regular Text/32pt/Bold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.52px;
}
.seperator {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6b6c72;
  /* Regular Text/32pt/Bold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.52px;
}
.truckNo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #6b6c72;
  /* Regular Text/32pt/Bold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.52px;
}
.stickyDetailsPageHeader {
  //position: sticky;
  top: 120px;
  right: 0;
  z-index: 1;
  background-color: #fff;
}
.certificateSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.certificatesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}
.certificates {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}
.categories {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
}
.categoryRow {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 8px;
}
.detailsTabButtons {
  position: sticky;
  top: 100px;
  right: 0;
  z-index: 2;
  padding-top: 32px;
  background-color: #fff;
  padding-bottom: 16px;
}
// .cardStyle {
//   border-radius: 12px;
//   border: 1px solid var(--T---LT-Gray, #eceef1);
//   background: #fff;
//   padding: 40px;
//   margin-top: 16px;
// }
.nextPreviousContainer {
  display: flex;
  width: 408px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
.arrowContainer {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 80px;
  border: 1px solid var(--Gray-05, #d2d7de);
  background: var(--Gray-09, #fcfcfc);
}
.settlementsTableDetailsConatainer {
  display: flex;
  padding: 16px;
  flex-direction: column;
  //align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-top: 2px solid var(--Gray-06, #e3e5e8);
  border-top: 2px solid var(--Gray-06, color(display-p3 0.8902 0.898 0.9098));
  // background: var(--Gray-08, #f4f5f8);
  // background: var(--Gray-08, color(display-p3 0.9569 0.9608 0.9725));
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow-x: auto;
}
.loadTable {
  background-color: #fff;
  border: 1px solid var(--Gray-06, #e3e5e8);
  border: 1px solid var(--Gray-06, color(display-p3 0.8902 0.898 0.9098));
  border-radius: 8px;
}
.tableContainerSettlementsDriver {
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  border-top: 0;
  background: #fff;
  //position: sticky;
  // top: 20px;
  // right: 0;
  z-index: 1;
}
.tableTitleContainerSettlementsDriver {
  position: sticky;
  //top: 175px;
  //right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  background-color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  border-bottom: 0;
  margin-top: 16px;
}
.driversTableContainerSettlementsDriver {
  //position: relative;
  max-height: 550px;
  overflow-x: auto;
  position: sticky;
  //top: 200px;
  //right: 0;
  z-index: 1;
}
.driversTableContainerSettlementsDriver::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
.driversTableContainerSettlementsDriver::-webkit-scrollbar-thumb {
  //background-color: rgba(0, 0, 0, 0);
}
.driversTableContainerSettlementsDriver::-webkit-scrollbar-track {
  // background-color: rgba(0, 0, 0, 0);
}
