.drivers-page {
  background-color: #fff;
  position: relative;
  padding: 0 48px 24px;
  //overflow: hidden;
}
.driverStickyHeader {
  position: sticky;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
  padding-top: 24px;
  //padding-bottom: 25px;
  //padding-left: 48px;
}
.stickyDetailsHeader {
  position: sticky;
  top: 120px;
  right: 0;
  z-index: 1;
  //margin-bottom: 1px;
}
.stickyTabButtons {
  position: sticky;
  top: 100px;
  right: 0;
  z-index: 2;
  background-color: #fff;
  padding-top: 32px;
  //padding-bottom: 16px;
  margin-bottom: 16px;
}
.tableContainer {
  border-radius: 12px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  background: #fff;
  position: sticky;
  top: 20px;
  right: 0;
  z-index: 1;
}
.tableTitleContainer {
  position: sticky;
  top: 175px;
  right: 0;
  z-index: 2;
}
.driversTableContainer {
  position: relative;
  max-height: 550px;
  overflow-x: auto;
  position: sticky;
  //top: 200px;
  right: 0;
  z-index: 1;
}
.driversTableContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
.driversTableContainer::-webkit-scrollbar-thumb {
  //background-color: rgba(0, 0, 0, 0);
}
.driversTableContainer::-webkit-scrollbar-track {
  // background-color: rgba(0, 0, 0, 0);
}
