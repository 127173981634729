.inputDropdownFieldConatinerInputDropdown {
  position: relative;
  display: flex;
  //width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.inputDropdownFieldNameInputDropdown {
  color: var(--Gray-02, #6b6c73);
  color: var(--Gray-02, color(display-p3 0.4196 0.4235 0.4471));
  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.inputDropdownFieldStyleInputDropdown {
  position: relative;
  display: flex;
  //padding: 14px 16px 14px 16px;
  width: 100%;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-05, #d2d7de);
  border: 1px solid var(--Gray-05, color(display-p3 0.8314 0.8431 0.8627));
  background: var(--Gray-08, #f4f5f8);
  background: var(--Gray-08, color(display-p3 0.9569 0.9608 0.9725));
  //backdrop-filter: blur(5px);
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  /* Regular Text/16pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.inputDropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  //height: 3rem;
}
.inputBox {
  cursor: pointer;
  //padding: 5px 5px 5px 3px;
  background: var(--Gray-08, #f4f5f8);
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //line-height: 1.7;
  width: 100%;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  /* Regular Text/16pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
//For Chrome and Safari
.inputBox[type="number"]::-webkit-inner-spin-button,
.inputBox[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Remove default arrow buttons */
  margin: 0; /* Optional: adjust positioning if needed */
}
//For Firefox
.inputBox[type="number"] {
  -moz-appearance: textfield; /* Remove default arrow buttons */
}
.dropdownPickerInputDropdown {
  position: absolute;
  //top: 100%;
  //left: 0;
  width: 100%;
  min-height: 100%;
  max-height: 16rem;
  padding: 16px;
  overflow-y: scroll;
  border-radius: 8px;
  border: 1px solid var(--Gray-04, #b8bec7);
  background: var(--White, #fff);
  margin-top: 8px;
  z-index: 1000;
}
.dropdownOptionsConatiner {
  display: flex;
  flex-direction: column;
  //align-items: center;
  gap: 12px;
  align-self: stretch;
  max-height: 200px; /* Change as necessary */
  overflow-y: auto;
}
