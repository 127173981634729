.dataCellLoadStyles {
  display: flex;
  min-height: 56px;
  padding: 18px 16px;
  align-items: center;
  //gap: 8px;
  flex: 1 0 0;
  //border: 1px solid var(--Gray-06, #e3e5e8);
  border-bottom: 1px solid var(--Gray-06, color(display-p3 0.8902 0.898 0.9098));
  background: var(--Gray-08, #f4f5f8);
  //background: var(--Gray-08, color(display-p3 0.9569 0.9608 0.9725));
  //box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08) inset;
  //box-shadow: 0px 0px 8px 0px color(display-p3 0 0 0 / 0.08) inset;
  overflow: hidden;
  color: var(--Primary-Gray, #393a3d) !important;
  text-overflow: ellipsis;
  /* Regular Text/14pt/Regular */
  font-family: "Red Hat Display" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.inputCellStyles {
  display: flex;
  min-height: 56px;
  padding: 18px 16px;
  align-items: center;
  //gap: 8px;
  flex: 1 0 0;
  border: 1px solid var(--Gray-06, #e3e5e8);
  border: 1px solid var(--Gray-06, color(display-p3 0.8902 0.898 0.9098));
  background: var(--Gray-08, #f4f5f8);
  //background: var(--Gray-08, color(display-p3 0.9569 0.9608 0.9725));
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08) inset;
  //box-shadow: 0px 0px 8px 0px color(display-p3 0 0 0 / 0.08) inset;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.subTotal {
  overflow: hidden;
  color: var(--T---Black, #000);
  color: var(--T---Black, color(display-p3 0 0 0));
  text-align: right;
  text-overflow: ellipsis;

  /* Regular Text/14pt/Regular */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.subTotalAmountAdd {
  overflow: hidden;
  //color: var(--Purple, #725ef6);
  color: #00bc7f;
  text-overflow: ellipsis;

  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.subTotalAmountDeduction {
  overflow: hidden;
  color: var(--Red, #ff4517);
  color: var(--Red, color(display-p3 0.9529 0.3373 0.1922));
  text-overflow: ellipsis;

  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.inputStyle {
  overflow: hidden;
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  text-overflow: ellipsis;

  /* Regular Text/14pt/Regular */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
