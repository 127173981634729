// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

.login-page {
  padding: 24px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.header {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0px 24px;
  width: 100%;
  height: 32px;
}

.loginBlockOverlay {
  position: absolute;
  height: 650px;
  width: 450px;
  border-radius: 15px;
  top: 50%;
  right: 0%;
  transform: translate(-25%, -50%);
  z-index: 2;
  opacity: 0.8;
}
.loginForm {
  position: absolute;
  width: 420px;
  height: 460px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  top: 50%;
  right: 3.5%;
  //right: 49px;
  transform: translate(-14%, -50%);
  z-index: 2;
  padding: 40px 40px;
}
.login-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin-top: 0px;
}
.tLogo {
  height: 50px;
}
.transportLogo {
  height: 35px;
  margin-left: 10px;
}

@media screen and (min-width: 1100px) and (max-width: 1299px) and (min-height: 700px) {
  .loginBlockOverlay {
    top: 50%;
    right: 0%;
    transform: translate(-25%, -50%);
  }
  .loginForm {
    top: 50%;
    right: 4.2%;
    transform: translate(-14%, -50%);
  }
}
@media screen and (min-width: 1300px) and (max-width: 1379px) and (min-height: 800px) {
  .loginBlockOverlay {
    top: 50%;
    right: 0%;
    transform: translate(-25%, -50%);
  }
  .loginForm {
    top: 50%;
    right: 3.6%;
    transform: translate(-14%, -50%);
  }
}
@media screen and (min-width: 1380px) and (max-width: 1599px) and (min-height: 850px) {
  .loginBlockOverlay {
    top: 50%;
    right: 0%;
    transform: translate(-25%, -50%);
  }
  .loginForm {
    top: 50%;
    right: 3.5%;
    //right: 49px;
    transform: translate(-14%, -50%);
  }
}
@media screen and (min-width: 1600px) and (max-width: 1700px) and (min-height: 950px) {
  .loginBlockOverlay {
    top: 50%;
    right: 0%;
    transform: translate(-25%, -50%);
  }
  .forgotPasswordBlock .loginForm {
    top: 50%;
    right: 2.95%;
    transform: translate(-14%, -50%);
  }
}
@media screen and (min-width: 1701px) and (min-height: 950px) {
  .loginBlockOverlay {
    top: 50%;
    right: 0%;
    transform: translate(-25%, -50%);
  }
  .loginForm {
    top: 50%;
    right: 2.8%;
    transform: translate(-14%, -50%);
  }
}
