// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
.login-details {
  margin-top: 50px;
}
.login-form-group {
  margin-top: 12px;
  margin-bottom: 0px;
  height: 88px;
  position: relative;
}
.login-form-label {
  display: inline-flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(6px);
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  //margin-left: 22px;
  position: absolute; /* Position the label absolutely */
  top: 0%; /* Align the label vertically in the middle */
  transform: translateY(-50%); /* Adjust vertical alignment */
  left: 16px; /* Add left padding */
  z-index: 1;
}
.login-form-input {
  display: flex;
  width: 100%;
  height: 52px;
  padding: 14px 32px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  background: rgba(244, 245, 248, 0.2);
  box-shadow: 0px 9px 14.4px 0px rgba(0, 0, 0, 0.3) inset;
  backdrop-filter: blur(5px);
  font-size: 16px;
  color: #fff;
  color: color(display-p3 1 1 1);
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: 400;
}
.login-form-input:not(:placeholder-shown) {
  background: rgba(244, 245, 248, 0.2);
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
.invalid-feedback {
  font-size: 14px;
  margin-left: 22px; /* Adjust the font size as needed */
}
.keepMeLoggedIn {
  color: var(--White, color(display-p3 1 1 1));
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
// .keepCheckbox {
//   border-radius: 6.71px;
//   border: 1.677px solid rgba(244, 245, 248, 0.2);
//   border: 1.677px solid color(display-p3 0.9569 0.9608 0.9725 / 0.2);
//   background: rgba(255, 255, 255, 0.2);
//   background: color(display-p3 1 1 1 / 0.2);
//   backdrop-filter: blur(5px);
// }
/* Hide default checkbox */
.keepCheckbox {
  display: none;
}
/* Style for custom checkbox label */
.keepCheckbox-label {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  //vertical-align: middle;
  cursor: pointer;
  color: var(--White, color(display-p3 1 1 1));
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-left: 30px; /* Space for custom checkbox */
  margin-left: 10px;
}
/* Box */
.keepCheckbox + .keepCheckbox-label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 6.71px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  background: #212121;
  background: color(display-p3 1 1 1 / 0.2);
  backdrop-filter: blur(5px);
  //padding-right: 15px;
}
/* Checked box */
.keepCheckbox:checked + .keepCheckbox-label:before {
  background: var(--Brand-Colors-Transport-Brand-Color, #212121);
  border-color: var(--Brand-Colors-Transport-Brand-Color, #eceef1);
}
/* Checkmark */
.keepCheckbox:checked + .keepCheckbox-label:after {
  content: "";
  position: absolute;
  top: 2px; /* Adjust position of checkmark */
  left: 8px; /* Adjust position of checkmark */
  width: 8px; /* Size of checkmark */
  height: 18px; /* Size of checkmark */
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: flex;
  justify-content: left;
  align-items: center;
}
.forgotPassword {
  font-family: "Red Hat Display", sans-serif;
  font-display: swap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;

  cursor: pointer;
}
.login-button {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  background: #212121;
  box-shadow: 0px 18px 17.1px 0px rgba(0, 0, 0, 0.5);

  cursor: pointer;
}
