// @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");

.otp-details {
  margin-top: 80px;
}
.otp-heading {
  //width: 385px;
  color: var(--White, #fff);
  color: var(--White, color(display-p3 1 1 1));
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 46px;
}
.otp-message {
  //width: 386px;
  //height: 51px;
  color: var(--White, #fff);
  color: var(--White, color(display-p3 1 1 1));
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.otp-inputs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.otp-input {
  width: 54px; /* Adjust width of each input box */
  height: 54px; /* Adjust height of each input box */
  text-align: center;
  padding: 10px;
  font-size: 18px; /* Adjust font size */
  border-radius: 39px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  border: 1px solid var(--T---LT-Gray, color(display-p3 0.9255 0.9333 0.9451));
  background: rgba(244, 245, 248, 0.2);
  background: color(display-p3 0.9569 0.9608 0.9725 / 0.2);
  backdrop-filter: blur(5px);
  color: var(--White, #fff);
  color: var(--White, color(display-p3 1 1 1));
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
}
//For Chrome and Safari
.otp-input[type="number"]::-webkit-inner-spin-button,
.otp-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Remove default arrow buttons */
  margin: 0; /* Optional: adjust positioning if needed */
}
//For Firefox
.otp-input[type="number"] {
  -moz-appearance: textfield; /* Remove default arrow buttons */
}
.otp-loader {
  //padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.otp-verification {
  display: flex;
  justify-content: center;
  align-items: center;
  //padding-top: 40px;
}
.resendCode {
  height: 22px;
  color: var(--White, #fff);
  color: var(--White, color(display-p3 1 1 1));
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
}
.resendButton {
  height: 22px;
  color: color(display-p3 1 0.3725 0);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: bolder;
  margin-left: 5px;
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
}
