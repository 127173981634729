.accountSettingsPage {
  display: flex;
  padding: 24px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}
.accountInformationConatiner {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  background: #fff;
}
.profile-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.informationDetails {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.detailsSectionAccount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  //margin-bottom: 40px;
}
.valueConatinerPOC {
  display: flex;
  //justify-content: flex-start;
  //align-items: flex-start;
  //align-content: flex-start;
  text-align: left;
  //width: 100%;
  gap: 40px;
  justify-self: stretch;
  align-self: stretch;
  flex-wrap: wrap;
  border: 0px solid var(--Gray-06, #e3e5e8);
}
.horizontalLine {
  display: flex;
  align-self: stretch;
  border-top: 1px solid #e3e5e8;
  margin: 0;
}
.buttonsSection {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
}
