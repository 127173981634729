.toggleFieldConatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
.toggleFieldName {
  margin-bottom: 0;
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.labelToggleContainer {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-06, #e3e5e8);
}
.toggleLabel {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
