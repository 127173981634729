.expirationsConatiner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.expirationTable {
  width: 100%;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
}
.expirationTableRow:hover {
  background-color: #fafafa; /* Change the background color on hover */
  cursor: pointer;
}
