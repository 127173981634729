// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

.bgLabels {
  display: inline-flex;
  align-items: center;
  //height: 16px;
  width: auto;
  border-radius: 4px;
}
.bgLabelName {
  //color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0;
  padding: 4px 8px;
  border-radius: 4px;
  //border: 0.1px solid #ffffff;
}
.arrowLabels {
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 4px;
  width: auto;
  border-radius: 4px;
}
