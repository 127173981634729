.forgotPasswordBlock {
  position: absolute;
  width: 420px;
  height: 410px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  top: 50%;
  right: 3.5%;
  //right: 49px;
  transform: translate(-14%, -50%);
  z-index: 2;
  padding: 40px 40px;
}
.forgotPasswordMailImage {
  position: absolute;
  top: -58px;
  left: 39%;
  z-index: 1; // Ensure the image is behind the content
}
.forgotPasswordHeader {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 18px;
  margin-top: 10px;
}
.forgotPasswordTitle {
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Red Hat Display", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  //box-shadow: 0px 18px 17.1px 0px rgba(0, 0, 0, 0.5);
}
.forgotPasswordMessage {
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  //box-shadow: 0px 18px 17.1px 0px rgba(0, 0, 0, 0.5);
}
.forgotPassword-form-group {
  margin-top: 12px;
  margin-bottom: 0px;
  height: 75px;
  position: relative;
}
.forgotPassword-form-label {
  display: inline-flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(6px);
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  //margin-left: 22px;
  position: absolute; /* Position the label absolutely */
  top: 0%; /* Align the label vertically in the middle */
  transform: translateY(-50%); /* Adjust vertical alignment */
  left: 16px; /* Add left padding */
  z-index: 1;
}
.forgotPassword-form-input {
  display: flex;
  width: 100%;
  height: 52px;
  padding: 14px 32px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  background: rgba(244, 245, 248, 0.2);
  box-shadow: 0px 9px 14.4px 0px rgba(0, 0, 0, 0.3) inset;
  backdrop-filter: blur(5px);
  font-size: 16px;
  color: #fff;
  color: color(display-p3 1 1 1);
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: 400;
}
.forgotPassword-form-input:not(:placeholder-shown) {
  background: rgba(244, 245, 248, 0.2);
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
.invalid-feedback {
  font-size: 14px;
  margin-left: 22px; /* Adjust the font size as needed */
}
.resetLinkButton {
  display: flex;
  width: 400px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
}
.backToLogin {
  display: flex;
  padding: 10px 0px;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  text-align: center;
  //font-feature-settings: "clig" off, "liga" off;
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-top: 22px;
  cursor: pointer;
}
@media screen and (min-width: 1100px) and (max-width: 1299px) and (min-height: 700px) {
  .forgotPasswordBlock {
    top: 50%;
    right: 4.2%;
    transform: translate(-14%, -50%);
  }
}
@media screen and (min-width: 1300px) and (max-width: 1379px) and (min-height: 800px) {
  .forgotPasswordBlock {
    top: 50%;
    right: 3.6%;
    transform: translate(-14%, -50%);
  }
}
@media screen and (min-width: 1380px) and (max-width: 1599px) and (min-height: 850px) {
  .forgotPasswordBlock {
    top: 50%;
    right: 3.5%;
    //right: 49px;
    transform: translate(-14%, -50%);
  }
}
@media screen and (min-width: 1600px) and (max-width: 1700px) and (min-height: 950px) {
  .forgotPasswordBlock {
    top: 50%;
    right: 2.95%;
    transform: translate(-14%, -50%);
  }
}
@media screen and (min-width: 1701px) and (min-height: 950px) {
  .forgotPasswordBlock {
    top: 50%;
    right: 2.8%;
    transform: translate(-14%, -50%);
  }
}
