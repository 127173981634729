.tabButtonsContainer {
  display: inline-flex;
  //   align-items: center;
  //   justify-content: center;
  // margin-top: 48px;
}
.tabButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  height: 32px;
  //gap: 8px;
  //border-radius: 16px;
  border: 1px solid var(--Gray-05, #d2d7de);
  //border-right: 0;
  //color: var(--Primary-Gray, #393a3d);
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */

  //   @each $button in date, duration, no_date {
  //     &.#{$button} {
  //       padding: 4px 8px;
  //       background-color: #ffffff;
  //       border: 1px solid #ced4da;
  //       color: black;
  //       cursor: pointer;
  //       flex: 1;

  //       &:not(:last-child) {
  //         border-radius: 0;
  //       }

  //       &:first-child {
  //         border-top-left-radius: 3px;
  //         border-bottom-left-radius: 3px;
  //       }

  //       &:last-child {
  //         border-top-right-radius: 3px;
  //         border-bottom-right-radius: 3px;
  //       }
  //     }

  //     &.#{$button}.active {
  //       background-color: #ced4da;
  //       font-weight: 800;
  //       color: black;
  //     }
  //   }
}
