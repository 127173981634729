.addLoadPage {
  display: flex;
  padding: 24px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}
.titleButtonSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.detailsConatiner {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}
.loadDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  background: #fff;
  width: 55%;
}
.addLocationDetails {
  display: flex;
  //padding-top: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.addStopContainer {
  display: flex;
  padding: 0px 24px 0px 44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.vertical-line-destination {
  border-right: 1px solid #d4d7dc; /* Adjust thickness and color as needed */
  height: 85px;
  margin: 0px 30px 5px 70px;
}
.mapRecommendationContainer {
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  //width: 100%;
}
