.stickyHeaderSectionSettelments {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding-top: 24px;
  padding-bottom: 24px;
  //background-color: #007bff;
}
.tabButtonsCalendarSettelments {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  padding-top: 24px;
  padding-bottom: 24px;
}
.calenderChangeTabButtonsContainerSettelments {
  display: flex;
  align-items: center;
  gap: 8px;
}
.stickyPartContaier {
  position: sticky;
  top: 60px;
  right: 0;
  z-index: 2;
}
.stickyDetailsHeaderSettelments {
  // position: -webkit-sticky;
  // position: sticky;
  // top: 120px; /* Adjust according to the height of previous sticky elements */
  // z-index: 3;
}
.tableContainerSettlements {
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  border-top: 0;
  background: #fff;
  //position: sticky;
  // top: 20px;
  // right: 0;
  // z-index: 1;
}
.tableTitleContainerSettlements {
  //position: sticky;
  // top: 175px;
  // right: 0;
  //z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  border-radius: 0;
  background: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  border-bottom: 0;
  //margin-top: 16px;
}
.searchBox {
  display: flex;
  width: 214px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-05, #d2d7de);
  background: var(--Gray-09, #fcfcfc);
}
.searchTextStyle {
  color: var(--Gray-02, #6b6c73);
  //text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
}
.driversTableContainerSettlements {
  //position: relative;
  max-height: 550px;
  overflow-x: auto;
  //position: sticky;
  //top: 200px;
  //right: 0;
  //z-index: 2;
}
.driversTableContainerSettlements::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
.driversTableContainerSettlements::-webkit-scrollbar-thumb {
  //background-color: rgba(0, 0, 0, 0);
}
.driversTableContainerSettlements::-webkit-scrollbar-track {
  // background-color: rgba(0, 0, 0, 0);
}
#settelments-chart {
  height: 210px;
  //margin-bottom: 24px;
}
#settelments-chart .hide {
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  opacity: 0;
}
/* Initial state */
// #settelments-chartt {
//   transition: opacity 0.3s ease, margin-bottom 0.3s ease;
// }

/* Animation class */
#settelments-chart {
  animation: fadeAndMargin 0.3s forwards;
}
// /* Keyframes for animation */
@keyframes fadeAndMargin {
  0% {
    opacity: 1;
    margin-bottom: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1; /* Lower the opacity */
    //margin-bottom: 20px; /* Adjust the value as needed */
  }
}
// @keyframes jadoo {
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//   }
// }
// #settelments-chart {
//   animation: jadoo linear;
//   animation-timeline: view();
//   animation-range: leav;
// }
.scrollable-chart {
  //margin-top: 24px;
}
/* Ensure these classes are specific enough */
// #settelments-chart.scrollable-chart-opacity {
//   opacity: 0.5; /* Lower the opacity */
//   transition: opacity 0.3s ease; /* Smooth transition */
// }
// #settelments-chart.scrollable-chart-margin-bottom {
//   margin-bottom: 20px; /* Adjust the value as needed */
//   transition: margin-bottom 0.3s ease; /* Smooth transition */
// }
#settelments-chart .apexcharts-legend-series {
  display: flex;
  align-items: center !important;
}
#settelments-chart .apexcharts-legend-text {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-left: 0;
}
.slanted-lines {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  border: 1px solid white;
  overflow: hidden; /* To ensure pseudo-element stays within the div */
  margin-right: 3px;
}
.slanted-lines::before {
  content: "";
  position: absolute;
  top: -50%; /* Position it to cover the entire div */
  left: -50%; /* Position it to cover the entire div */
  width: 200%; /* Make it large enough to cover the div when rotated */
  height: 200%;
  background: repeating-linear-gradient(
    59deg,
    /* Angle of the lines */ white,
    /* Line color */ white 0px,
    /* Line thickness */ transparent 2px,
    /* Space after the line */ transparent 4px /* Space thickness */
  );
}
.triangle {
  width: 0;
  height: 0;
  border-top: 40px solid #007bff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  // border-bottom: 40px solid #007bff;
}
