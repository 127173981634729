.searchBox {
  display: flex;
  width: 214px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-05, #d2d7de);
  background: var(--Gray-09, #fcfcfc);
}
.searchTextStyle {
  color: var(--Gray-02, #0c0d0d);
  //text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
}
