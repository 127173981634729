// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
.container {
  display: flex;
  background-color: #f9fafb;
  height: 100dvh;
}
main {
  width: 100%;
  position: relative;
}
.gradientBackground {
  position: fixed;
  top: 0;
  //z-index: -1;
}
.childrenShrink {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  padding: 0 0 0 96px;
  //overflow: hidden;
}
.childrenExpand {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  padding: 0 0 0 244px;
  //overflow: hidden;
}
.sidebarContainer {
  display: flex;
  flex-direction: column;
  //height: 100dvh;
  height: fit-content;
  //border-right: 1px solid var(--Gray-06, #e3e5e8);
  //background: #000;
  position: absolute;
  top: 0;
  left: 24px;
  bottom: 0;
  z-index: 4;
  //overflow: hidden;
}
.logoContainer {
  display: flex;
  align-items: center;
  height: 72px;
  //padding: 20px;
  //border-bottom: 1px solid var(--Gray-06, #e3e5e8);
  //background-color: #000;
}
.logoImage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collapseSibebar {
  transition: transform 1s ease;
}
.collapseSibebar:hover {
  //transform: rotate(-90deg);
  transition: transform 1s ease;
}
.rotateLogo {
  transform: rotate(-90deg);
}
.stableLogo {
  transform: rotate(0deg);
}
.expandSidebar {
  transition: transform 1s ease;
}
.expandSidebar:hover {
  //transform: rotate(90deg);
  transition: transform 1s ease;
}
.rotateLogoCW {
  transform: rotate(90deg);
}
.rotateOnHover:hover {
  .collapseSibebar:hover {
    transform: rotate(-90deg);
    transition: transform 1s ease;
  }
}
.arrowButtonContainer {
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  //border-top: 1px solid var(--Gray-06, #e3e5e8);
  //border-bottom: 1px solid var(--Gray-06, #e3e5e8);
  background: var(--Menu-Gray, #fff);
  //padding: 20px;
}
.arrowButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 80px;
  border: 1px solid var(--Gray-05, #d2d7de);
  background: var(--White, #fff);
}
.hamburgerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 22px;
  //background: rgba(0, 0, 0, 1); /* Define your gradient colors */
}
.hamburgerShrink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0px;
  border-radius: 80px;
  border: 1px solid var(--Gray-05, #d2d7de);
  background: rgba(255, 255, 255, 0.1);
  //position: absolute;
  //position: fixed;
  //top: 6.7%;
  //top: 55px;
  //left: 13%;
  //left: 196px;
  //transform: translate(-1.85%, 12%);
  z-index: 4;
  //overflow: auto;
}
.hamburgerExpand {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0px;
  border-radius: 80px;
  border: 1px solid var(--Gray-05, #d2d7de);
  background: rgba(255, 255, 255, 0.1); //position: absolute;
  // top: 6.7%;
  // left: 3.9%;
  //top: 55px;
  //left: 55px;
  //transform: translate(-3.9%, 12%);
  z-index: 4;
}
.hamburger-react {
  position: relative !important;
  left: -9px;
  top: 0px;
}
.sidebarContent {
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
  align-self: stretch;
  color: var(--Primary-Gray, #393a3d);
  //height: 100%;
  //width: auto;
  //transition: all 1s ease-out;
  transition: margin-right 20s ease-in;
  border-radius: 12px;
  // border-bottom-left-radius: 0;
  // border-bottom-right-radius: 0;
  border: 1px solid var(--Gray-06, #e3e5e8);
  background: var(--White, #fff);
  box-shadow: 4px 8px 24px 0px rgba(0, 0, 0, 0.08);
  padding-top: 16px;
}
.sidebarLink {
  display: flex;
  //justify-content: center;
  align-items: center;
  background-color: var(--Menu-Gray, #fff);
  color: var(--Primary-Gray, #393a3d);
  //padding: 24px 23px 24px 24px;
  padding: 16px 23px 16px 24px;
  gap: 15px;
  //border-radius: 12px;
}
.sidebarLink:hover {
  background: var(--Menu-Gray, #f9f9fb);
  color: var(--Primary-Gray, #393a3d);
}
.sidebarLinkActive {
  color: var(--Primary-Gray, #393a3d);
  border-right: 4px solid var(--Brand-Colors-Transport-Brand-Color, #000);
  background: var(--Menu-Gray, #f9f9fb);
}
.sidebarItems {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.bottomOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  //align-items: center;
  gap: 32px;
  //margin: auto 0px 0px 0px;
  // position: absolute;
  // z-index: 4;
}
.searchContainerSidebar {
  display: flex;
  align-items: center;
  // margin: 0px 23px 0px 24px;
  gap: 15px;
  transition: all 2s ease;
}
.notificationContainer {
  display: flex;
  align-items: center;
  //margin: 0px 23px 0px 24px;
  gap: 15px;
  transition: all 2s ease;
}
.addOptionsConatiner {
  display: flex;
  padding: 0px 12px;
  flex-direction: row;
  justify-content: center;
  //align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 0px solid var(--Gray-06, #e3e5e8);
  //margin-bottom: 32px;
}
.closeOptions {
  display: flex;
  padding: 8px;
  //justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  background: var(--White, #fff);
}
.addNewButton {
  display: flex;
  //justify-content: left;
  align-items: center;
  margin: 16px 15px 16px 15px;
  gap: 15px;
  transition: all 2s ease;
}
.addNewDriver {
}
.profileSection {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-top: 1px solid var(--Gray-05, #d2d7de);
  //background-color: #fff;
  // border: 1px solid var(--Gray-06, #e3e5e8);
  // border-bottom-left-radius: 12px;
  // border-bottom-right-radius: 12px;
}
.logoutContainer {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-05, #d2d7de);
}
.sidebarProfile {
  //width: 165px;
  //height: 72px;
  //padding: 14px;
  //border-top: 1px solid var(--Gray-06, #e3e5e8);
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
  //align-self: stretch;
  transition: all 2s ease;
}
.adminProfile {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-bottom: 0;
  white-space: nowrap;
}
.adminDesignation {
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-bottom: 0;
  white-space: nowrap;
}
.menuItem {
  position: relative;
  &:hover .subOptionsPopup {
    display: block;
  }
}
.subOptionsPopup {
  display: none;
  position: absolute;
  top: 0;
  right: -135px; /* Adjust this value as needed */
  background-color: #fff;
  border: 1px solid #ccc;
  width: 133px;
  //padding: 3px 3px;
  z-index: 2;
  border-radius: 12px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  box-shadow: 0px 12px 20px 0px #ebeffa;
  /* Additional styles for popup appearance */
}
.subOptionsPopupClicked {
  //display: block;
  position: absolute;
  top: 0;
  right: -135px; /* Adjust this value as needed */
  background-color: #fff;
  border: 1px solid #ccc;
  width: 133px;
  //padding: 3px 3px;
  z-index: 100;
  border-radius: 12px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  box-shadow: 0px 12px 20px 0px #ebeffa;
  /* Additional styles for popup appearance */
}
.subOptionLink {
  display: block;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid var(--T---LT-Gray, #eceef1);
  //border-radius: 12px;
  //background: var(--White, #fff);
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.subOptionLink:hover {
  background: var(--White, #f9f9fb);
}
.hashLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000;
}
// @media screen and (min-width: 320px) and (max-width: 1099px) and (min-height: 700px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     //-webkit-transform: translate(-4.6%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     //-webkit-transform: translate(-16.7%, 12%);
//   }
// }
// @media screen and (min-width: 1100px) and (max-width: 1299px) and (min-height: 700px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     //-webkit-transform: translate(-4.6%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     //-webkit-transform: translate(-16.7%, 12%);
//   }
// }
// @media screen and (width: 1194px) and (height: 706px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     //-webkit-transform: translate(-4.6%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     //-webkit-transform: translate(-16.7%, 12%);
//   }
// }
// @media screen and (min-width: 1300px) and (max-width: 1379px) and (min-height: 800px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     // -webkit-transform: translate(-4.1%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     //-webkit-transform: translate(-14.6%, 12%);
//   }
// }
// @media screen and (width: 1366px) and (height: 896px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     // -webkit-transform: translate(-4.1%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     //-webkit-transform: translate(-14.6%, 12%);
//   }
// }
// @media screen and (min-width: 1380px) and (max-width: 1599px) and (min-height: 850px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     //-webkit-transform: translate(-3.9%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     //-webkit-transform: translate(-13.85%, 12%);
//   }
// }
// @media screen and (width: 1440px) and (height: 900px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     //-webkit-transform: translate(-3.9%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     //-webkit-transform: translate(-13.85%, 12%);
//   }
// }
// @media screen and (min-width: 1600px) and (max-width: 1700px) and (min-height: 950px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     //-webkit-transform: translate(-3.4%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     //-webkit-transform: translate(-12.1%, 12%);
//   }
// }
// @media screen and (width: 1680px) and (height: 1050px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     //-webkit-transform: translate(-3.4%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     //-webkit-transform: translate(-12.1%, 12%);
//   }
// }
// @media screen and (min-width: 1701px) and (min-height: 950px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     //-webkit-transform: translate(-3.2%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     // -webkit-transform: translate(-11.6%, 12%);
//   }
// }
// @media screen and (width: 1728px) and (height: 989px) {
//   .hamburgerExpand {
//     top: 55px;
//     left: 55px;
//     //-webkit-transform: translate(-3.2%, 12%);
//   }
//   .hamburgerShrink {
//     top: 55px;
//     left: 196px;
//     // -webkit-transform: translate(-11.6%, 12%);
//   }
// }
