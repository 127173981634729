$asset-base: 'https://transportinc-assets.s3.us-east-2.amazonaws.com/';

// Mixins

// Fonts
@mixin font-size($size, $base: 20) {
  font-size: $size + px; // fallback for old browsers
  font-size: ($size / $base) * 1rem;
}

// Font Face
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix') format('embedded-opentype'),
      url($file-name + '.woff') format('woff'),
      url($file-name + '.ttf') format('truetype'),
      url($file-name + '.svg##{$font-name}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

// Clearfix
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

// Border Radius
@mixin border-radius($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// Custom Border Radius
@mixin border-radius-custom(
  $topleft: 5px,
  $topright: 5px,
  $bottomleft: 5px,
  $bottomright: 5px
) {
  -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
  -moz-border-radius: $topleft $topright $bottomright $bottomleft;
  border-radius: $topleft $topright $bottomright $bottomleft;
}

// Box Shadow
@mixin box-shadow($x: 0px, $y: 3px, $blur: 3px, $spred: 0px, $color: #fff) {
  -webkit-box-shadow: $x $y $blur $spred $color;
  -moz-box-shadow: $x $y $blur $spred $color;
  box-shadow: $x $y $blur $spred $color;
}

// Transition
@mixin transition($prop: all, $time: 0.5s, $ease: linear) {
  -webkit-transition: $prop $time $ease;
  -moz-transition: $prop $time $ease;
  -o-transition: $prop $time $ease;
  -ms-transition: $prop $time $ease;
  transition: $prop $time $ease;
}

// Rotate
@mixin rotate($rotate: 90deg) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  transform: rotate($rotate);
}

// Border
@mixin border($width: 1px, $style: solid, $color: #dddddd) {
  border: $width $style $color;
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); //IE8
}

// Color
@mixin colormixin($color) {
  color: $color;
}

// Font style
@mixin stylemixin($style) {
  font-style: $style;
}

// Uppercase
@mixin uppercase {
  text-transform: uppercase;
}

// Lowercase
@mixin lowercase {
  text-transform: lowercase;
}

// Arrow Up
@mixin arrow-up($size: 5px, $color: $white) {
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid $color;
}

// Arrow Down
@mixin arrow-down($size: 5px, $color: $white) {
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid $color;
}

// Arrow Right
@mixin arrow-right($size: 5px, $color: $white) {
  width: 0;
  height: 0;
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;
  border-left: $size solid $color;
}

// Arrow Left
@mixin arrow-left($size: 5px, $color: $white) {
  width: 0;
  height: 0;
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;
  border-right: $size solid $color;
}

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Vertical Centering
@mixin center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;
  @include prefix(transform, translate(-50%, -50%), 'webkit' 'ms');
}

@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number
    {
      #{$o}: nth($args, $i + 1);
    }
  }
  position: $position;
}

// Positioning helpers
@mixin absolute($args: '') {
  @include position(absolute, $args);
}

@mixin fixed($args: '') {
  @include position(fixed, $args);
}

@mixin relative($args: '') {
  @include position(relative, $args);
}

@mixin prefix($property, $value, $vendors: webkit moz ms o, $default: true) {
  @if $vendors {
    @each $vendor in $vendors {
      #{"-" + $vendor + "-" + $property}: #{$value};
    }
  }
  @if $default {
    #{$property}: #{$value};
  }
}

// Media Queries
@mixin screen($size) {
  $desktop: '(max-width: 1800px)'; //xxxl
  $md-desktop: '(max-width: 1599px)'; //xxl
  $sm-desktop: '(max-width: 1199px)'; //xl
  $tablet: '(max-width: 1023px)'; //lg
  $landscape: '(max-width: 767px)'; //md
  $mobile: '(max-width: 567px)'; //sm
  @if $size == desktop {
    @media only screen and #{$desktop} {
      @content;
    }
  } @else if $size == md-desktop {
    @media only screen and #{$md-desktop} {
      @content;
    }
  } @else if $size == sm-desktop {
    @media only screen and #{$sm-desktop} {
      @content;
    }
  } @else if $size == tablet {
    @media only screen and #{$tablet} {
      @content;
    }
  } @else if $size == landscape {
    @media only screen and #{$landscape} {
      @content;
    }
  } @else if $size == mobile {
    @media only screen and #{$mobile} {
      @content;
    }
  } @else {
    @media only screen and #{$size} {
      @content;
    }
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}
@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      $start-color,
      $end-color
    );
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

@mixin background($image-name, $position, $repeat) {
  background: {
    image: url($asset-base + $image-name);
    position: $position;
    repeat: $repeat;
  }
}

@mixin gradientImgBackground(
  $gradient-start,
  $gradient-end,
  $image-name,
  $position,
  $repeat
) {
  background: linear-gradient($gradient-start, $gradient-end),
    url($asset-base + $image-name);
  background-position: $position;
  background-repeat: $repeat;
}
