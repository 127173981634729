.driver-table {
  margin-bottom: 12px !important;
}
.driver-table:hover {
  background-color: #fafafa; /* Change the background color on hover */
  border-bottom-left-radius: 12px;
  cursor: pointer;
}

.tableStickyHeader {
  position: sticky;
  top: 0px;
  right: 0;
  z-index: 2;
}
.optionsMenuPopup {
  position: absolute;
  //top: 0;
  right: 2.5%;
  z-index: 1;
}
.optionsMenuPopupLabel {
  position: absolute;
  //top: 0;
  right: 2.5%;
  z-index: 3;
}
.stickyPagination {
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 4;
  //padding: 16px 0;
  border-top: 1px solid #e0e0e0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.noRecordsFound {
  color: var(--Primary-Gray, #393a3d);
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
}
