.greeting {
  display: flex;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.drivers-page {
  background-color: #f9fafb;
  position: relative;
  padding: 0 48px 24px;
  //overflow: hidden;
}
.driverStickyHeaderDriver {
  display: flex;
  //align-items: center;
  gap: 10px;
  //align-self: stretch;
  //position: sticky;
  top: 0;
  right: 0;
  //background-color: #f9fafb;
  z-index: 5;
  padding-top: 24px;
  //padding-bottom: 25px;
  //padding-left: 48px;
}
.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}
.actionsConatiner {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.stickyDetailsHeader {
  //position: sticky;
  top: 120px;
  right: 0;
  z-index: 1;
  //margin-bottom: 1px;
}
.stickyTabButtonsDashboard {
  position: sticky;
  top: 100px;
  right: 0;
  z-index: 2;
  //background-color: #fff;
  //padding-top: 32px;
  //padding-bottom: 16px;
  //margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.nextSettlements {
  display: flex;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  background: #fff;
  margin-top: 16px;
}
.nextSettlementsTitle {
  display: flex;
  padding: 24px 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.nextSettlementsHeadingDateSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 22px;
  align-self: stretch;
}
.nextSettlementsHeading {
  color: var(--Gray-02, #6b6c73);
  color: var(--Gray-02, color(display-p3 0.4196 0.4235 0.4471));
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.currentWeek {
  color: var(--T---Black, #000);
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dotSeperater {
  color: var(--T---Black, color(display-p3 0 0 0));
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
}
.netPaySection {
  display: flex;
  //width: 673px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.neyPayValue {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: "Red Hat Display", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(
    --Gradient-Test,
    linear-gradient(
      135deg,
      #00fcff -57.3%,
      #c22eff 34.9%,
      #ff7b39 101.62%,
      #ff7b38 130.58%
    )
  );
  background: var(
    --Gradient-Test,
    linear-gradient(
      135deg,
      color(display-p3 0.1216 0.9703 0.9922) -57.3%,
      color(display-p3 0.701 0.2333 0.9632) 34.9%,
      color(display-p3 0.9989 0.5166 0.2966) 101.62%,
      color(display-p3 1 0.5176 0.2941) 130.58%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.neyPayDecimalValue {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(
    --Gradient-Test,
    linear-gradient(
      135deg,
      color(display-p3 0.1216 0.9703 0.9922) -57.3%,
      color(display-p3 0.701 0.2333 0.9632) 34.9%,
      color(display-p3 0.9989 0.5166 0.2966) 101.62%,
      color(display-p3 1 0.5176 0.2941) 130.58%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.neyPayField {
  color: var(--Gray-02, #6b6c73);

  color: var(--Gray-02, color(display-p3 0.4196 0.4235 0.4471));
  /* Regular Text/12pt/Regular */
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tableContainerDashboard {
  border-radius: 12px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  background: #fff;
  //position: sticky;
  top: 20px;
  right: 0;
  z-index: 1;
  margin-top: 16px;
  width: 100%;
}
.tableTitleContainerDashboard {
  //position: sticky;
  top: 175px;
  right: 0;
  z-index: 2;
}
.leaderBoardContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}
.searchBox {
  display: flex;
  width: 214px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-05, #d2d7de);
  background: var(--Gray-09, #fcfcfc);
}
.searchTextStyle {
  color: var(--Gray-02, #6b6c73);
  //text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
}
.driversTableContainer {
  position: relative;
  max-height: 550px;
  overflow-x: auto;
  position: sticky;
  //top: 200px;
  right: 0;
  z-index: 1;
  padding: 0px 16px 16px 16px;
  //background-color: #fff;
}
.driversTableContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
.driversTableContainer::-webkit-scrollbar-thumb {
  //background-color: rgba(0, 0, 0, 0);
}
.driversTableContainer::-webkit-scrollbar-track {
  // background-color: rgba(0, 0, 0, 0);
}
.recentSettelmentsTableContainer {
  border-radius: 10px;
  //border: 1px solid #f9fafb;
  // background: var(--Page-Color, #f9fafb);
  // background: var(--Page-Color, color(display-p3 0.9765 0.9804 0.9843));
  //width: 100%;
}
.calenderChangeButtonContainerDashboard {
  display: flex;
  //flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  //padding-top: 32px;
}
.toast-message {
  //display: flex;
  //padding: 8px 12px 8px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  // background: rgba(0, 188, 249, 0.1);
  // background: color(display-p3 0 0.724 0.9526 / 0.1);
  color: var(--Blue, var(--Blue, #00bfff));
  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Toastify__toast {
  height: auto !important;
  min-height: auto !important;
}
