//@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");

.regularPrimaryCrossIcon {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 120px;
  padding: 10px 16px;
  border-radius: 120px;
  background: #000000;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    border: 2px solid var(--Primary-Gray, #000000);
    background: var(--White, #fff);
  }
  &:disabled {
    background-color: #f4f5f8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
  // .primaryButton:hover {
  //   background-color: #E3E5E8;
  // }
}
.regularSecondaryCrossIcon {
  width: 40px;
  height: 40px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  border: 1px solid var(--Gray-06, color(display-p3 0.8902 0.898 0.9098));
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #ffffff;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    border: 1px solid #babec5;
  }
  &:disabled {
    border: 1px solid#E3E5E8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
// .secondaryButton:hover {
//   border: 1px solid #babec5;
// }
.regularGhostCrossIcon {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #ffffff;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    background-color: #eceef1;
  }
  &:disabled {
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
// .ghostButton:hover {
//   background-color: #eceef1;
// }

.smallPrimaryCrossIcon {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #000000;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    border: 2px solid var(--Primary-Gray, #000000);
    background: var(--White, #fff);
  }
  &:disabled {
    background-color: #f4f5f8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }

  // .primaryButton:hover {
  //   background-color: #E3E5E8;
  // }
}
.smallSecondaryCrossIcon {
  width: 32px;
  height: 32px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  border: 1px solid var(--Gray-06, color(display-p3 0.8902 0.898 0.9098));
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #ffffff;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    border: 1px solid #babec5;
  }
  &:disabled {
    border: 1px solid#E3E5E8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
// .secondaryButton:hover {
//   border: 1px solid #babec5;
// }
.smallGhostCrossIcon {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #ffffff;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    background-color: #eceef1;
  }
  &:disabled {
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
// .ghostButton:hover {
//   background-color: #eceef1;
// }
