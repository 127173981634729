@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.scroll-element {
  opacity: 1;
  transition: opacity 1s ease-out, transform 1s ease-out;

  &.is-hidden {
    opacity: 0;
    transform: translateY(-20px);
    animation: fade-out 1s forwards;
  }
}
