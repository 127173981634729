.addDriverLicenseCard {
  background-color: #fff;
  position: relative;
  top: 20%;
  left: 45%;
  transform: translate(-25%, -15%);
  border-radius: 24px;
  width: 352px;
}
.addDriverInputCard {
  background-color: #fff;
  position: relative;
  top: 20%;
  left: 45%;
  transform: translate(-42%, -18%);
  border-radius: 24px;
  //width: 1180px;
  width: 65%;
}
.addDriverContainer {
  display: flex;
  //padding: 32px;
  //width: 400px;
  //height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //gap: 40px;
  border-radius: 24px;
  border: 1px solid var(--Gray-05, #d2d7de);
  border: 1px solid var(--Gray-05, color(display-p3 0.8314 0.8431 0.8627));
  background: #fff;
  box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 24px 48px 0px color(display-p3 0 0 0 / 0.08);
  backdrop-filter: blur(4px);
}
.addDriverTitleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  white-space: nowrap;
  padding: 24px;
  border-radius: 16px 16px 0px 0px;
  border-bottom: 1px solid var(--Gray-06, #e3e5e8);
  background: #fff;
}
.addDriverTitleMessageSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.addDriverTitleStyle {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.addDriverMessageStyle {
  color: var(--T---Black, #000);
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.closeAddDriver {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 80px;
  border: 1px solid var(--Gray-05, #d2d7de);
  border: 1px solid var(--Gray-05, color(display-p3 0.8314 0.8431 0.8627));
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
}
.addDriverButtonContainer {
  display: flex;
  padding: 16px 24px;
  justify-content: right;
  align-items: center;
  align-self: stretch;
  border-radius: 0px 0px 16px 16px;
  border-top: 1px solid var(--Gray-06, #e3e5e8);
  background: #fff;
}
.licenseDetailsSection {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}
.uploadButtonSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.loadingDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.loaderContainer {
  display: flex;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 120px;
  background: var(--White, hsl(0, 0%, 100%));
  background: var(--White, color(display-p3 1 1 1));
  height: 50px;
}
.fetchDetails {
  display: flex;
  padding: 23px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 120px;
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: "clig" off, "liga" off;

  /* Special Use/Cta */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (min-width: 320px) and (max-width: 1099px) and (min-height: 700px) {
  .addDriverLicenseCard {
    top: 20%;
    left: 45%;
    transform: translate(-25%, -15%);
  }
}
@media screen and (min-width: 1100px) and (max-width: 1299px) and (min-height: 700px) {
  .hamburgerExpand {
    top: 55px;
    left: 55px;
    //-webkit-transform: translate(-4.6%, 12%);
  }
  .hamburgerShrink {
    top: 55px;
    left: 189px;
    //-webkit-transform: translate(-16.7%, 12%);
  }
}
@media screen and (width: 1194px) and (height: 706px) {
  .hamburgerExpand {
    top: 55px;
    left: 55px;
    //-webkit-transform: translate(-4.6%, 12%);
  }
  .hamburgerShrink {
    top: 55px;
    left: 189px;
    //-webkit-transform: translate(-16.7%, 12%);
  }
}
@media screen and (min-width: 1300px) and (max-width: 1379px) and (min-height: 800px) {
  .addDriverLicenseCard {
    top: 20%;
    left: 45%;
    transform: translate(-25%, -15%);
  }
  .addDriverInputCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (width: 1366px) and (height: 896px) {
  .addDriverLicenseCard {
    top: 20%;
    left: 45%;
    transform: translate(-25%, -15%);
  }
  .addDriverInputCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (min-width: 1380px) and (max-width: 1599px) and (min-height: 850px) {
  .addDriverLicenseCard {
    top: 20%;
    left: 45%;
    transform: translate(-25%, -15%);
  }
  .addDriverInputCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -18%);
  }
}
@media screen and (width: 1440px) and (height: 900px) {
  .addDriverLicenseCard {
    top: 20%;
    left: 45%;
    transform: translate(-25%, -15%);
  }
  .addDriverInputCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -18%);
  }
}
@media screen and (min-width: 1600px) and (max-width: 1700px) and (min-height: 950px) {
  .addDriverLicenseCard {
    top: 20%;
    left: 45%;
    transform: translate(-25%, -10%);
  }
  .addDriverInputCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
@media screen and (width: 1680px) and (height: 1050px) {
  .addDriverLicenseCard {
    top: 20%;
    left: 45%;
    transform: translate(-25%, -10%);
  }
  .addDriverInputCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
@media screen and (min-width: 1701px) and (min-height: 950px) {
  .addDriverLicenseCard {
    top: 20%;
    left: 45%;
    transform: translate(-25%, -11%);
  }
  .addDriverInputCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
@media screen and (width: 1728px) and (height: 989px) {
  .addDriverLicenseCard {
    top: 20%;
    left: 45%;
    transform: translate(-25%, -11%);
  }
  .addDriverInputCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
