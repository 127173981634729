.fieldConatiner {
  //display: flex;
  //width: 100%;
  min-width: 200px;
  padding: 0px 12px;
  align-items: center;
  gap: 12px;
  border-left: 1px solid var(--T---LT-Gray, #eceef1);
}
.fileldValue {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.fieldName {
  min-width: 50px;
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
