.icon-group {
  display: flex;
  align-items: center;
}

.icon {
  width: 30px;
  height: 30px;
  background-color: #ddd; /* Replace with your icon styles */
  margin-right: 5px; /* Adjust spacing between icons */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.plus-count {
  background-color: #4caf50; /* Replace with your background color */
  color: #fff; /* Replace with your text color */
  padding: 5px;
  border-radius: 50%;
  font-size: 14px;
}
