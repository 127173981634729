// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap");

.horizontalNavTabsLink {
  display: inline-flex;
  align-items: flex-start;
  padding: 8px;
}
.verticalNavTabsLink {
  display: flex;
  flex-direction: column;
  width: 100px;
  padding: 8px;
}
.navTabsLink {
  height: auto;
  width: auto;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--Gray-03, #8c9097);
  border-bottom: 1px solid var(--Gray-05, #d2d7de);

  &:hover {
    color: var(--Gray-03, #8c9097);
    border-bottom: 1px solid var(--Gray-05, #d2d7de);
  }
}
.navTabsLink.activeTab {
  color: var(--T---Black, #000);
  border-bottom: 1px solid var(--Brand-Colors-Transport-Brand-Color, #f00);
}
