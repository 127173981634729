.detailsStickyHeader {
  position: sticky;
  //top: 25px;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
  padding-top: 24px;
  //padding-bottom: 16px;
  //padding-left: 48px;
}
.detailsPage {
  background-color: #fff;
  position: relative;
  padding: 0 48px 24px;
  //overflow: hidden;
}
.serialNumbers {
  display: flex;
  align-items: center;
  gap: 12px;
}
.driverNo {
  color: #6b6c72;
  /* Regular Text/32pt/Bold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.52px;
}
.seperator {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6b6c72;
  /* Regular Text/32pt/Bold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.52px;
}
.truckNo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #6b6c72;
  /* Regular Text/32pt/Bold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.52px;
}
.stickyDetailsPageHeader {
  //position: sticky;
  top: 120px;
  right: 0;
  z-index: 1;
  background-color: #fff;
}
.certificateSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.certificatesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}
.certificates {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}
.categories {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
}
.categoryRow {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 8px;
}
.detailsTabButtons {
  position: sticky;
  top: 100px;
  right: 0;
  z-index: 2;
  padding-top: 32px;
  background-color: #fff;
  padding-bottom: 16px;
}
// .cardStyle {
//   border-radius: 12px;
//   border: 1px solid var(--T---LT-Gray, #eceef1);
//   background: #fff;
//   padding: 40px;
//   margin-top: 16px;
// }
