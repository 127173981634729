//@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");

.regularPrimaryButton {
  width: 106px;
  height: 40px;
  border: none;
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #000000;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    color: #000000;
    border: 2px solid var(--Primary-Gray, #000000);
    background: var(--White, #fff);
  }
  &:disabled {
    background-color: #f4f5f8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
  // .primaryButton:hover {
  //   background-color: #da3703;
  // }
}
.regularSecondaryButton {
  width: 106px;
  height: 40px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  border: 1px solid var(--Gray-06, color(display-p3 0.8902 0.898 0.9098));
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #ffffff;
  font-size: 14px;
  color: #393a3d;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    border: 1px solid #babec5;
  }
  &:disabled {
    border: 1px solid#E3E5E8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
.regularGhostButton {
  width: 106px;
  height: 40px;
  border: none;
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #ffffff;
  font-size: 14px;
  color: #393a3d;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    background-color: #eceef1;
  }
  &:disabled {
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
.smallPrimaryButton {
  width: 88px;
  height: 32px;
  border: none;
  border-radius: 120px;
  padding: 8px 12px;
  background-color: #000000;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    color: #000000;
    border: 2px solid var(--Primary-Gray, #000000);
    background: var(--White, #fff);
  }
  &:disabled {
    background-color: #f4f5f8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }

  // .primaryButton:hover {
  //   background-color: #da3703;
  // }
}
.smallSecondaryButton {
  width: 88px;
  height: 32px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  border: 1px solid var(--Gray-06, color(display-p3 0.8902 0.898 0.9098));
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
  border-radius: 120px;
  padding: 8px 12px;
  background-color: #ffffff;
  font-size: 14px;
  color: #393a3d;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    border: 1px solid #babec5;
  }
  &:disabled {
    border: 1px solid#E3E5E8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
.smallGhostButton {
  width: 88px;
  height: 32px;
  border: none;
  border-radius: 120px;
  padding: 8px 12px;
  background-color: #ffffff;
  font-size: 14px;
  color: #393a3d;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    background-color: #eceef1;
  }
  &:disabled {
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
.responsiveRegularPrimaryButton {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #000000;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    color: #000000;
    border: 2px solid var(--Primary-Gray, #000000);
    background: var(--White, #fff);
  }
  &:disabled {
    background-color: #f4f5f8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }

  // .primaryButton:hover {
  //   background-color: #da3703;
  // }
}
.responsiveRegularSecondaryButton {
  width: 100%;
  height: 40px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  border: 1px solid var(--Gray-06, color(display-p3 0.8902 0.898 0.9098));
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #ffffff;
  font-size: 14px;
  color: #393a3d;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    border: 1px solid #babec5;
  }
  &:disabled {
    border: 1px solid#E3E5E8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
.responsiveRegularGhostButton {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 120px;
  padding: 10px 16px;
  background-color: #ffffff;
  font-size: 14px;
  color: #393a3d;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    background-color: #eceef1;
  }
  &:disabled {
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
.responsiveSmallPrimaryButton {
  width: 100%;
  height: 32px;
  border: none;
  border-radius: 120px;
  padding: 8px 12px;
  background-color: #000000;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    background-color: #2a5bb6;
  }
  &:disabled {
    background-color: #f4f5f8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }

  // .primaryButton:hover {
  //   background-color: #da3703;
  // }
}
.responsiveSmallSecondaryButton {
  width: 100%;
  height: 32px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  border: 1px solid var(--Gray-06, color(display-p3 0.8902 0.898 0.9098));
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
  border-radius: 120px;
  padding: 8px 12px;
  background-color: #ffffff;
  font-size: 14px;
  color: #393a3d;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    border: 1px solid #babec5;
  }
  &:disabled {
    border: 1px solid#E3E5E8;
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
.responsiveSmallGhostButton {
  width: 100%;
  height: 32px;
  border: none;
  border-radius: 120px;
  padding: 8px 12px;
  background-color: #ffffff;
  font-size: 14px;
  color: #393a3d;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  &:not(:disabled):hover {
    // Your hover effect styles here
    // This will apply hover effect only when button is enabled
    background-color: #eceef1;
  }
  &:disabled {
    color: #babec5;
    //pointer-events: none; /* Disable pointer events when button is disabled */
  }
}
