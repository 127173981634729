.preferencesConatiner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.preferencesDetails {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.preferencesDetailsSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.valueConatiner {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 40px;
  align-self: stretch;
  flex-wrap: wrap;
  border: 0px solid var(--Gray-06, #e3e5e8);
}
.horizontalLine {
  display: flex;
  align-self: stretch;
  border-top: 1px solid #e3e5e8;
  margin: 0;
}
