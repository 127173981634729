.updateDriverContainer {
  display: flex;
  padding: 32px;
  //width: 400px;
  //height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  border-radius: 24px;
  border: 1px solid var(--Gray-05, #d2d7de);
  border: 1px solid var(--Gray-05, color(display-p3 0.8314 0.8431 0.8627));
  background: #fff;
  box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 24px 48px 0px color(display-p3 0 0 0 / 0.08);
  backdrop-filter: blur(4px);
}
.updateDriverTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  white-space: nowrap;
}
.updateDriverStyle {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.licenseImageSection {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.inputSectionDriver {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.inputRowDriver {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  align-self: stretch;
}
.loginCTABox {
  display: flex;
  min-width: 400px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.payPlan {
  display: flex;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
}
.toast-message {
  //display: flex;
  //padding: 8px 12px 8px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  // background: rgba(0, 188, 249, 0.1);
  // background: color(display-p3 0 0.724 0.9526 / 0.1);
  color: var(--Blue, var(--Blue, #00bfff));
  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Toastify__toast {
  height: auto !important;
  min-height: auto !important;
}
