.uploadImageSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  align-self: stretch;
  border-right: 1px solid var(--Gray-06, #e3e5e8);
  background: var(--White, #fff);
}
.frontSideUploadSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.backSideUploadSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.frontSideTitle {
  display: flex;
}
.dlStyle {
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.fsStyle {
  color: var(--T---Black, #000);
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.frontSideUploadInput {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 201px;
  //padding: 32px 40px;
  border-radius: 12px;
  border: 1px dashed var(--Gray-03, #8b9098);
  background: var(--Gray-09, #fcfcfc);
  backdrop-filter: blur(5px);
  position: relative;
}

.imageUploadButton {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-04, #b8bec7);
  border: 1px solid var(--Gray-04, color(display-p3 0.7294 0.7451 0.7725));
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
}
.uploadIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //z-index: 1;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-04, #b8bec7);
  border: 1px solid var(--Gray-04, color(display-p3 0.7294 0.7451 0.7725));
  background: var(--White, #fff);
  background: var(--White, color(display-p3 1 1 1));
}
.licenseCLassSection {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.chooseFileContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.dragDrop {
  color: var(--black-80, rgba(0, 0, 0, 0.8));
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%; /* 23.8px */
}
.chooseFile {
  color: var(--primary-primary-700, #007ccf);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
  text-align: center;
  cursor: pointer;
}
