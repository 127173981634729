.addOtherRevenueCard {
  background-color: #fff;
  position: relative;
  top: 20%;
  left: 45%;
  transform: translate(-42%, -18%);
  border-radius: 24px;
  //width: 1180px;
  width: 40%;
}
.addOtherRevenueContainer {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--Gray-05, #d2d7de);
  border: 1px solid var(--Gray-05, color(display-p3 0.8314 0.8431 0.8627));
  //   background: url(<path-to-image>) lightgray -310.492px 0px / 210.104% 69.668% no-repeat,
  //     #fff;
  //   background: url(<path-to-image>) lightgray -310.492px 0px / 210.104% 69.668% no-repeat,
  //  color(display-p3 1 1 1);
  //box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.08);
  //box-shadow: 0px 24px 48px 0px color(display-p3 0 0 0 / 0.08);
  backdrop-filter: blur(4px);
}
.unassignVehicleTitleMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.addOtherRevenueTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  white-space: nowrap;
}
.addOtherRevenueStyle {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.fieldsConatiner {
  display: flex;
  align-items: center;
  gap: 24px;
}
.unassignVehicleMessage {
  display: flex;
  color: var(--Gray-02, #6b6c73);
  color: var(--Gray-02, color(display-p3 0.4196 0.4235 0.4471));

  /* Regular Text/18pt/Semibold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.unassignVehicleNumber {
  color: var(--T---Black, color(display-p3 0 0 0));

  /* Regular Text/18pt/Semibold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 4px 0 4px;
}
.removeSection {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 12px;
}
@media screen and (min-width: 320px) and (max-width: 1099px) and (min-height: 700px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (min-width: 1100px) and (max-width: 1299px) and (min-height: 700px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (width: 1194px) and (height: 706px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (min-width: 1300px) and (max-width: 1379px) and (min-height: 800px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (width: 1366px) and (height: 896px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -15%);
  }
}
@media screen and (min-width: 1380px) and (max-width: 1599px) and (min-height: 850px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -18%);
  }
}
@media screen and (width: 1440px) and (height: 900px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-42%, -18%);
  }
}
@media screen and (min-width: 1600px) and (max-width: 1700px) and (min-height: 950px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
@media screen and (width: 1680px) and (height: 1050px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
@media screen and (min-width: 1701px) and (min-height: 950px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
@media screen and (width: 1728px) and (height: 989px) {
  .assignVehicleCard {
    top: 20%;
    left: 45%;
    transform: translate(-41%, -12%);
  }
}
