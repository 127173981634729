.expirations {
  display: flex;
  padding: 6px 8px !important;
  align-items: center;
  gap: 8px;
  border-radius: 40px !important;
  border: 1px solid var(--Gray-06, #e3e5e8);
  overflow: hidden;
  color: var(--Primary-Gray, #393a3d) !important;
  text-overflow: ellipsis;
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
