.datePickerContainer {
  display: flex;
  //width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.datePickerFieldName {
  color: var(--T---Black, #000);
  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.datePickerInputField {
  display: flex;
  padding: 8px 16px 8px 16px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-05, #d4d7dc);
  background: var(--Gray-08, #f4f5f8);
  //backdrop-filter: blur(5px);
  //width: 95%;
}
.datePicker {
  display: flex;
  width: 100%;
  align-items: center;
  align-self: stretch;
  // background: var(--Gray-08, #f4f5f8);
  padding: 0px !important;
  text-align: left;
  border: none !important;
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  /* Regular Text/16pt/Medium */
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal !important;
  //z-index: 0;

  //width: 90% !important;
}
.react-datepicker-wrapper {
  font-size: 16px !important;
  line-height: normal !important;
  background: #f4f5f8 !important;
  //width: 80% !important;
}
.react-datepicker__input-container {
  font-size: 16px !important;
  line-height: normal !important;
  //background: red !important;
  // width: 80% !important;
}
// span:not(:last-child)::after {
//   content: "";
//   display: inline-block;
//   text-align: center;
//   vertical-align: middle;
//   font-family: "Red Hat Display", sans-serif;
//   margin-bottom: 4px;
//   margin-left: 8px;
//   font-size: 20px;
//   height: 16px;
//   width: 16px;
//   color: #fff;
//   line-height: 18.52px;
// }
