.driver-table {
  margin-bottom: 12px !important;
}
.driver-table:hover {
  background-color: #fafafa; /* Change the background color on hover */
  border-bottom-left-radius: 12px;
  cursor: pointer;
}
.tableStickyHeader {
  position: sticky;
  top: 0px;
  right: 0;
  z-index: 2;
}
// .optionsMenuPopup {
//   position: absolute;
//   //top: 0;
//   right: 2.5%;
//   z-index: 1;
// }
