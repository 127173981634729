#app_page_container {
  #app_sider {
    background-color: #f1f1f1;
    .ant-menu {
      background-color: transparent;
      .ant-menu-item-selected {
        background-color: #f9f9f9;
      }
    }
  }
  #app_content_container {
    padding: 1rem 2rem;
  }
}
