.custom-dropdown {
  position: relative;
  width: 200px; /* Adjust width as needed */
}
.inputDropdownFieldConatiner {
  display: flex;
  //width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.inputDropdownFieldName {
  color: var(--T---Black, #000);
  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.dropdown-header {
  cursor: pointer;
  display: flex;
  padding: 8px 16px 8px 16px;
  width: 100%;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-05, #d2d7de);
  border: 1px solid var(--Gray-05, color(display-p3 0.8314 0.8431 0.8627));
  background: var(--Gray-08, #f4f5f8);
  background: var(--Gray-08, color(display-p3 0.9569 0.9608 0.9725));
}
.selectedItem {
  height: auto;
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  /* Regular Text/16pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.selectedItem.disabled {
  color: #ccc;
  pointer-events: none;
}
.dropdown-arrow {
  font-size: 0.8rem;
}
.dropdownPicker {
  position: absolute;
  // top: auto; /* Resetting the top property */
  // bottom: 100%; /* Positioning the dropdown above the field */
  left: 0;
  width: 100%;
  min-height: fit-content;
  max-height: 16rem;
  padding: 16px;
  overflow-y: scroll;
  border-radius: 8px;
  border: 1px solid var(--Gray-04, #b8bec7);
  background: var(--White, #fff);
  margin-top: 8px;
  z-index: 1000;
}

.dropdownOptionsConatiner {
  display: flex;
  flex-direction: column;
  //align-items: center;
  gap: 12px;
  align-self: stretch;
  margin-bottom: 0;
}
ul {
  // list-style-type: none;
  // padding: 0;
  // margin: 0;
  // position: absolute; /* Set position to absolute */
  // z-index: 1; /* Ensure the dropdown appears on top of other content */
  // background-color: #ffffff; /* Background color of the dropdown */
  // border: 1px solid #ccc; /* Add a border */
  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add a box shadow for depth */
}

li {
  //padding: 8px 12px;
  cursor: pointer;
}

li:hover {
  //background-color: #f2f2f2; /* Change background color on hover */
}
