.radioButtonGroup {
  display: flex;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
}
.radioGroupName {
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));

  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.radioButton {
  display: flex;
  align-items: center;
  gap: 4px;
  //flex: 1 0 0;
}
.radioButtonLabel {
  color: var(--Primary-Gray, #393a3d);
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));

  /* Regular Text/14pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.radioButtonInput {
}
