.addTWICCard {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
}
.twicComment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}
.currentStatusTwicCard {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.addNewTwicCard {
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.twic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.twicDetails {
  //display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 24px;
  align-self: stretch;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  background: var(--Gray-09, #fcfcfc);
  margin-top: 32px;
}
.addTWIC {
  display: flex;
  width: 300px;
  align-items: center;
  gap: 16px;
}
.twicNumberValidation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}
.twicNumberLabels {
  display: flex;
  align-items: center;
  gap: 8px;
}
.twicNumber {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.twicValidation {
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.twicTypeDetails {
  display: flex;
  padding-left: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-left: 1px solid var(--Gray-05, #d2d7de);
}
.twicTypeValidation {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}

.twicExpire {
  display: flex;
  align-items: center;
  gap: 4px;
}
.twicExpireValue {
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.twicExpireDate {
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.twicLabels {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}
