.optionsMenu {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  border: 1px solid var(--Gray-05, #d2d7de);
}
.optionsMenuContainer {
  position: relative;
}
.optionsPopup {
  display: flex;
  //padding: 12px 16px;
  padding: 12px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  margin-top: 10px;
  //top: 5%;
  right: 0;
  z-index: 1; /* Make sure the filter bar appears above other elements */
  border-radius: 8px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  background: var(--White, #fff);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
}
.listOptions {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0 16px;
}
.listOptions:hover {
  background-color: #f8f8f8;
}
.optionsStyle {
  color: var(--Primary-Gray, #393a3d);
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}
