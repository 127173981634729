.driversFilterBar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Gray-06, #e3e5e8);
  background: var(--White, #fff);
  position: absolute;
  top: 62px;
  right: 22px;
  background-color: #ffffff;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 2; /* Make sure the filter bar appears above other elements */
  width: 420px;
  //height: 40rem;
  overflow: auto;
}
.filterTitle {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid var(--Gray-06, #e3e5e8);
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.driverFilter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.milesCovered {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid var(--Gray-06, #e3e5e8);
  background-color: #ffffff;
  white-space: nowrap;
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.driversFilterOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid var(--Gray-06, #e3e5e8);
  flex: 1;
}
.driversFilterValues {
  //height: 100%;
  overflow: auto;
  flex: 2;
}
.driversFilterValues::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
.driversFilterValues::-webkit-scrollbar-thumb {
  //background-color: rgba(0, 0, 0, 0);
}
.driversFilterValues::-webkit-scrollbar-track {
  //background-color: rgba(0, 0, 0, 0);
}
.valuesTitleConatiner {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.filterValuesTitle {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.seperatorDot {
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.selectedFilters {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Brand-Colors-Transport-Brand-LT-Shade, #ff5700);
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.searchContainerFilter {
  display: flex;
  padding: 0px 24px 8px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.searchBox {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 0px solid var(--Gray-05, #d2d7de);
  background: var(--Gray-08, #f4f5f8);
}
.searchCity {
  color: var(--Gray-02, #6b6c73);
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.filterActions {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--Gray-06, #e3e5e8);
}
.driverCountLabel {
  display: flex;
  flex: 1;
}
.driversCount {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.driversLabel {
  color: var(--Gray-02, color(display-p3 0.4196 0.4235 0.4471));
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
