.driversLicenseConatiner {
}
.licences {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.licenseDetails {
  //display: flex;
  padding: 24px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--T---LT-Gray, #eceef1);
  background: var(--Gray-09, #fcfcfc);
  margin-top: 32px;
}
.addLicense {
  display: flex;
  width: 300px;
  align-items: center;
  gap: 16px;
}
.licenseNumberValidation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}
.licenseNumber {
  color: var(--Primary-Gray, #393a3d);
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.licenseValidation {
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.licenseTypeDetails {
  display: flex;
  padding-left: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-left: 1px solid var(--Gray-05, #d2d7de);
}
.licenseTypeValidation {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}
.licenseType {
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.licenseDot {
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.licenseLocation {
  display: flex;
  align-items: center;
  gap: 4px;
}
.licenseExpireValue {
  color: var(--Gray-02, #6b6c73);
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.licenseExpireDate {
  color: var(--Primary-Gray, color(display-p3 0.2235 0.2275 0.2392));
  /* Regular Text/12pt/Medium */
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.licenseLabels {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}
