@import "./assets/styles/overrides.scss";
@import "./assets/styles/global.scss";

body {
  margin: 0;
  font-family: "Red Hat Display", sans-serif;
  // font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: proportional-nums;
  -moz-font-feature-settings: "pnum";
  -webkit-font-feature-settings: "pnum";
  font-feature-settings: "pnum";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
div#root {
  height: 100%;
  overflow: hidden;
  @include screen("tablet") {
    overflow: initial;
  }
}

#transport_admin_app {
  height: 100vh;
  overflow: auto;
}
