.vehicleDetailsPOC {
  //background-color: #f9fafb;
  position: relative;
  padding: 0 48px 24px;
  //overflow: hidden;
}
.detailsStickyHeaderVehicleDetails {
  position: sticky;
  //top: 25px;
  top: 0;
  right: 0;
  //background-color: #f9fafb;
  z-index: 2;
  padding-top: 24px;
  //padding-bottom: 16px;
  //padding-left: 48px;
  display: flex;
  //align-items: center;
  gap: 10px;
  align-self: stretch;
}
.backToDrivers {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.backRightArrow {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
}
.backLinkVehicleDetails {
  display: flex;
  width: 35px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.backLink:hover {
  color: var(--Primary-Gray, #393a3d);
}
.detailsPage {
  background-color: #f9fafb;
  position: relative;
  padding: 0 48px 24px;
  //overflow: hidden;
}
.serialNumbers {
  display: flex;
  align-items: center;
  gap: 12px;
}
.driverNoVehicleDetails {
  color: #fff;
  /* Regular Text/32pt/Bold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.52px;
}
.seperatorVehicleDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  /* Regular Text/32pt/Bold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.52px;
}
.truckNoVehicleDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  /* Regular Text/32pt/Bold */
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.52px;
}
.stickyTabButtonsVehicleDetails {
  position: sticky;
  top: 100px;
  right: 0;
  z-index: 2;
  //background-color: #fff;
  padding-top: 32px;
  //padding-bottom: 16px;
  margin-bottom: 16px;
}
.stickyDetailsPageHeader {
  //position: sticky;
  top: 120px;
  right: 0;
  z-index: 1;
  background-color: #f9fafb;
}
.certificateSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.certificatesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}
.certificates {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}
.categories {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
}
.categoryRow {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 8px;
}
.detailsTabButtonsVehicleDetails {
  position: sticky;
  top: 100px;
  right: 0;
  z-index: 2;
  padding-top: 32px;
  //background-color: #f9fafb;
  padding-bottom: 16px;
}
// .cardStyle {
//   border-radius: 12px;
//   border: 1px solid var(--T---LT-Gray, #eceef1);
//   background: #fff;
//   padding: 40px;
//   margin-top: 16px;
// }
